import { Routes, Route, Navigate } from "react-router-dom"
import { Sidebar } from "../components/Sidebar/Sidebar"
import { NavBar } from "../components/NavBar";
// import { PaymentsPage } from "../pages/payments/PaymentsPage";
import { Layout } from "antd"
import { AlertsReceivedPage } from "../pages/alertsReceived/AlertsReceivedPage"
// import { ReservationsRequestPage } from "../pages/reservationsRequest/ReservationsRequestPage";
// import { AnnouncementPage } from "../pages/announcement/AnnouncementPage";
// import { AdminCobrosPage } from "../pages/adminCobros/AdminCobrosPage";
// import { ZonasReceivedPage } from "../pages/zonas/ZonasReceivedPage";
import { SurveyPage } from "../pages/Surveys/SurveyPage"
import DetailTicket from "../pages/DetailsTickets/DetailTicket"
import TicketPage from "../pages/Tickets/TicketPage"
import ConfiguracionInicialPage from "./../pages/configuracionInicial/ConfiguracionInicialPage"
import NomenclaturaPage from "../pages/configuracionInicial/nomenclatura/NomenclaturaPage"
import CalendarTickets from "../pages/Tickets/components/calendar/CalendarTickets"
import InventoryPage from "../pages/inventario/InventoryPage"
import BuildingSketchPage from "../pages/buildingSketchs/BuildingSketchPage"
import EmployeedManagementPage from "../pages/employeedManagement/EmployeedManagementPage"
import ChecklistPage from "../pages/checklist/ChecklistPage"
import CheckListCategoryPage from "../pages/checklist/checklistCategory/CheckListCategoryPage"
import AdminCategoriesPage from "../pages/adminCategories/AdminCategoriesPage"
import DetailHandoverChecklist from "../pages/checklist/handoverCheck/components/detailsChecklist/DetailHandoverChecklist";
import AdminCheckPage from "../pages/checklist/adminCheck/AdminCheckPage"
import { ActivityMonitoringPage } from "../pages/checklist/activityMonitoring/ActivityMonitoringPage"
import AdminContractsPage from "../pages/adminContracts/AdminContractsPage"
import DefaultPage from "../mocks/DefaultPages"
import ActivesMaintenancePage from "../pages/activesMaintenance/ActivesMaintenancePage"
import { SurveyContextProvider } from "../pages/Surveys/contexts/SurveyContext"
import { SurveyFormContextProvidrer } from "../pages/Surveys/contexts/SurveyFormContext"
import HierarchyAdministrationPage from "../pages/hierarchyAdministration/HierarchyAdministrationPage"
import { useAppSelector } from "../../hooks/store"
import LibraryPage from '../pages/library/LibraryPage'
import BookingPage from "../pages/bookings/BookingPage"
import HomePage from "../pages/home/HomePage"
import AccessProvidersPage from "../pages/accessProviders/AccessProvidersPage"
import AmenitiesPage from "../pages/adminAmenities/AmenitiesPage"
import SalesStatementPage from "../pages/salesStatement/SalesStatementPage"
import MenuPage from "../components/Menu/MenuPage"
import AccessPage from "../pages/access/AccessPage"
import TypesNewsPage from "../pages/typesNews/TypesNewsPage";
import UserRolesMainPage from "../pages/userRoles/UserRolesMainPage";
import ConversationsPage from "../pages/messages/ConversationsPage";
import ImageChecklistPage from "../pages/adminChecklists/ImageChecklistPage";
import TypeAdminCheckPage from "../pages/adminChecklists/components/TypeAdminCheckPage";
import AdminChecklistItemsTasksPage from "../pages/adminChecklists/components/checklistAdmin/components/adminChecklistTasks/AdminChecklistItemsTasksPage";
import UsersPage from "../pages/users/UsersPage";
import ChecklistsAdminPage from "../pages/checklistsAdmin/ChecklistsAdminPage";
import HandoverChecklist from "../pages/checklist/handoverCheck/HandoverChecklist";
import ReceptionCheck from "../pages/checklist/receptionCheck/ReceptionCheck";
import TicketsAnalitycs from "../pages/Tickets/pages/TicketsAnalitycs/TicketsAnalitycs";
// import UserProfileActivityPage from "../pages/NewUsers/components/activityUser/UserProfileActivityPage";

const { Header, Sider, Content } = Layout;

export const DashboardRouter = () => {
  const theme = useAppSelector((state) => state.sidebarTheme);

  return (
    <Layout className="wrapper-container">
      <Sider
        className="sidebar"
        trigger={null}
        collapsible
        collapsed={true}
        //Cambiar el color del sider segun el theme desde la API
        style={{ height: "100vh", background: theme?.sidebarBackground }}
      >
        <Sidebar />
      </Sider>

      <Layout className="site-layout">
        <Header style={{ padding: 0 }}>
          <NavBar />
        </Header>

        <Content style={{ minHeight: 280 }}>
          <Routes>
            {/* GENERAL ROUTES */}
            <Route index element={<DefaultPage />} />
            <Route path="homePage" element={<HomePage />} />

            {/**MENU ROUTES */}
            <Route path="/menu/:menuId" element={<MenuPage />} />
            <Route path="/menu/:menuId/:submenuId" element={<MenuPage />} />

            <Route path="access/residential-access" element={<AccessPage />} />
            <Route path="access/providers-access" element={<AccessProvidersPage />} />

            {/**SURVEYS ROUTE */}
            <Route path="surveys" element={
              <SurveyContextProvider>
                <SurveyFormContextProvidrer>
                  <SurveyPage />
                </SurveyFormContextProvidrer>
              </SurveyContextProvider>
            } />
            {/* <Route path="destacados" element={<ImportantPage />} /> */}
            {/* <Route path="payments" element={<PaymentsPage />} /> */}
            {/*
            <Route
              path="zones"
              element={
                <ZonasReceivedPage
                  residentialData={authData.committee.residentialData}
                />
              }
            />
            <Route
              path="solicitudes-de-reserva"
              element={<ReservationsRequestPage />}
            />
            <Route path="announcements" element={<AnnouncementPage />} />
            <Route path="collection-managment" element={<AdminCobrosPage />} /> */}

            {/* ALERTAS */}
            <Route path="alertas" element={<AlertsReceivedPage />} />

            {/* ADMINISTRATION ROUTES */}
            <Route path="settings/managment-spaces" element={<HierarchyAdministrationPage />} />
            <Route path="settings/import-users" element={<NomenclaturaPage />} />
            <Route path="settings/users-profiles" element={<UsersPage />} />
            <Route path="settings/managment-employees" element={<EmployeedManagementPage />} />
            <Route path="settings/initial-settings" element={<ConfiguracionInicialPage />} />

            {/* ACTIVE MAINTENANCE ROUTE */}
            <Route path="settings/active-maintenance/" element={<ActivesMaintenancePage />} />
            <Route path="settings/active-maintenance/:activeId" element={<ActivesMaintenancePage />} />
            <Route path="settings/active-maintenance/:activeId/:historyId" element={<ActivesMaintenancePage />} />
            <Route path="settings/active-maintenance/:activeId/:historyId/:historyAction" element={<ActivesMaintenancePage />} />
            <Route path="settings/active-maintenance/:activeId/:historyId/:historyAction/:reportId" element={<ActivesMaintenancePage />} />

            {/* BOOKING ROUTES */}
            <Route path="bookings" element={<BookingPage />} />

            {/*AMENITITES ROUTES */}
            <Route path="/settings/managment-amenities" element={<AmenitiesPage />} />
            <Route path="/settings/managment-amenities/:idAmenity" element={<AmenitiesPage />} />

            {/* NEWS ROUTES */}
            <Route path="news" element={<TypesNewsPage />} />
            <Route path="news/:idNew" element={<TypesNewsPage />} />

            {/* TICKETS ROUTES */}
            <Route path="tickets/detail/:idTicket" element={<DetailTicket />} />
            <Route path="tickets/:typeTickets" element={<TicketPage />} />
            <Route path="tickets/analitycs" element={<TicketsAnalitycs />} />            
            <Route path="tickets/calendar-tickets" element={<CalendarTickets />} />
            <Route path="tickets/managment-categories" element={<AdminCategoriesPage />} />
            <Route path="tickets/managment-inventory" element={<InventoryPage />} />

            {/* MALLS ROUTES */}
            <Route path="mapas" element={<BuildingSketchPage />} />
            <Route path="contracts" element={<AdminContractsPage />} />

			{/* NEW TYPES CHECKLISTS ADMIN */}
			<Route path="/checklists-admin" element={<ChecklistsAdminPage />} />

            {/* ADMIN CHECKLISTS */}
            <Route path="/admin-check" element={<ImageChecklistPage />} />
            <Route path="/admin-check/:checklistIdPage" element={<TypeAdminCheckPage />} />
            <Route path="/admin-check/:checklistIdPage/:checklistCategoryId" element={<AdminChecklistItemsTasksPage />} />

            {/* CHECKLIST ROUTES */}
            <Route path="checklist-categorias/:checklistId" element={<CheckListCategoryPage />} />

            <Route path="checklist/:checkId" element={<ChecklistPage />} />
			      <Route path="/checklist/admin-entrega/:checklistId" element={<ReceptionCheck />} />

            <Route path="checklist/admin/:adminCheckId/" element={<AdminCheckPage />} />
            <Route path="checklist/admin/:adminCheckId/:idTaskChecklist" element={<AdminCheckPage />} />
            
			      <Route path="checklist/monitoring/:action/" element={<ActivityMonitoringPage />} />
            <Route path="checklist/monitoring/:action/:idDetail" element={<ActivityMonitoringPage />} />
			
			      <Route path="checklist-entrega/:checklistId" element={<HandoverChecklist />} />
            <Route path="checklist-entrega/:checklistId/:checklistTaskId" element={<DetailHandoverChecklist />} />

            {/* LIBRARY ROUTE */}
            <Route path="library" element={<LibraryPage />} />

            {/* DECLARACION DE VENTAS */}
            <Route path="settings/sales-statement" element={<SalesStatementPage />} />

            {/**ADMON. DE ROLES */}
            <Route path="/settings/managment-roles" element={<UserRolesMainPage />} />
            <Route path="/settings/managment-roles/:roleId" element={<UserRolesMainPage />} />
            <Route path="/settings/managment-roles/:roleId/:subId" element={<UserRolesMainPage />} />

            {/* MENSAJERIA */}
            <Route path="/messages" element={<ConversationsPage />} />

            <Route
              path="/*"
              element={<Navigate to="/404" />}
            />

            {/**OTHER ROUTES */}
            {/* <Route path="user-profile-activity-page" element={<UserProfileActivityPage />} /> */}
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};