import React from 'react'
import { Col, Modal, Row, Table, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveVisitor } from '../../../../store/access';
import { columnsAccessVisitorsTable, columnsHistorialAccessTable } from '../../../../helpers/accessHelper';
import CustomTable from '../../../UI/CustomTable/CustomTable';
import { CustomSpin } from '../../../UI/CustomSpin';
import { ACCESS_CODES } from '../../../../constants/modules/access/accessCodes';
import EmptyComponent from '../../../UI/EmptyComponent';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';


export const AccessVisitorsTab = ({ activeFeatures }) => {

    const [residentList, setResidentList] = useState([]);
    const dispatch = useDispatch();
    const { visitors } = useSelector(state => state.access);
    const columns = columnsAccessVisitorsTable();
    const [accessVisitorModalActive, setAccessVisitorModalActive] = useState(false);
    const columnsHistorialAccess = columnsHistorialAccessTable();
    const [visitorActive, seVisitorActive] = useState(null);
    const [loading, setLoad] = useState(true);
    useEffect(() => {

        setLoad(visitors.loadResidente);

    }, [visitors.loadResidente]);

    useEffect(() => {
        seVisitorActive(visitors.active);
        if (visitors.active) {

            if (visitors.active.records.length > 0) {
                let active = { ...visitors.active };
                active.records = visitors.active.records.map(item => {
                    let data = { ...item };
                    data.action_user_history = item.arrive ?
                        <>
                            <ArrowRightOutlined />
                            <span style={{ marginLeft: "10px" }}>Entró</span>
                        </> : <>
                            <ArrowLeftOutlined />
                            <span style={{ marginLeft: "10px" }}>Salió</span>
                        </>
                    return data;
                });
                seVisitorActive(active);
            }

        }
        if (!visitors.active) {
            handleStatusVisitorModal(false);
        }
    }, [visitors.active]);


    useEffect(() => {

        let _data = visitors.data.map((item, index) => {
            let data = { ...item }
            data.detail = (<img src="/assets/images/shape11.png" style={{ cursor: 'pointer' }} alt="Topia" onClick={() => handleStatusVisitorModal(true, index)} />);
            data.action_user = data.arrive ?
                <>
                    <ArrowRightOutlined />
                    <span style={{ marginLeft: "10px" }}>Entró</span>
                </> : <>
                    <ArrowLeftOutlined />
                    <span style={{ marginLeft: "10px" }}>Salió</span>
                </>
            return data;
        });

        setResidentList(_data);

    }, [visitors.data]);


    const handleStatusVisitorModal = (statusModal, index = null) => {

        if (index != null) {
            dispatch(setActiveVisitor(visitors.data[index]));
        } else {
            dispatch(setActiveVisitor(null));
        }


        setTimeout(() => {
            setAccessVisitorModalActive(statusModal);
        }, 50);

    }


    return (
        <>

            {!activeFeatures?.find(feature => feature.feature_code === ACCESS_CODES.ACCESS_VISITANTS)?.enabled ? <EmptyComponent label={'No tienes permiso para ver estos datos'} /> : loading ? (
                <div className={"loader"}>
                    <CustomSpin height='300px' size="large" />
                </div>
            ) : (
                <div>
                    <CustomTable columns={columns} dataSource={residentList} pagination={{ defaultPageSize: 7 }} />
                </div>
            )}
            <Modal
                className='side-modal'
                title="Datos del visitante"
                style={{ top: 0, right: 0 }}
                visible={accessVisitorModalActive}
                onCancel={() => handleStatusVisitorModal(false)}
                footer={null}
            >
                {
                    (visitorActive && visitorActive.profile_id) ?
                        <>

                            <Row className='section-modal'>

                                <Col span={24}>
                                    <div className='user-data'>
                                        {visitorActive.profile_data?.image && <img className='user-image' src={visitorActive.profile_data?.image} alt="Topia" />}

                                        <div className='user-info'>
                                            <span className='user-name'>
                                                {visitorActive.profile_data?.name}
                                            </span>
                                            {visitorActive.profile_data?.contact_phone &&
                                                <span style={{ color: "var(--primary)", display: 'flex', alignItems: 'flex-start' }}>
                                                    <img
                                                        style={{ marginLeft: "10px" }}
                                                        src="/assets/images/shape09.png"
                                                        alt="Topia" />

                                                    <span style={{ marginLeft: "5x" }}>
                                                        {visitorActive.profile_data?.phone}
                                                    </span>
                                                </span>

                                            }
                                        </div>

                                    </div>


                                </Col>
                            </Row>

                            <Row className='section-modal'>
                                <Col span={14} style={{ display: "flex" }}>

                                    <div className='alert-modal-header'>
                                        <span className='title'>Residencial que visita:</span>
                                        <p>{visitorActive.residential_data?.address}</p>
                                    </div>

                                </Col>
                                <Col span={10}>
                                </Col>
                            </Row>

                            <Row className='section-modal'>
                                <Col span={24} style={{ display: "flex" }}>

                                    <div className='alert-modal-header'>
                                        <span className='title'>Historial de entradas y salidas:</span>
                                    </div>

                                </Col>
                                <Col span={24}>
                                    <Table dataSource={visitorActive.records} columns={columnsHistorialAccess} pagination={{ defaultPageSize: 2 }} />
                                </Col>
                            </Row>

                        </>
                        :
                        <>
                            <Row className='section-modal'>
                                <Col span={24} style={{ textTransform: "uppercase", textAlign: "center" }}>
                                    <b>
                                        Datos de perfil no encontrados
                                    </b>
                                </Col>
                            </Row>

                        </>

                }

            </Modal>
        </>
    )
}