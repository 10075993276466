import { useAppSelector } from '../../../../../../hooks/store'
import { verifyResponse } from '../../../../../helpers/verifyResponse'
import { getGeneralAnalitycsTicket } from '../../../Services/getGeneralAnalitycsTicket'

const useTicketsAnalitycsServices = () => {
    const { idToken, currentRol } = useAppSelector(state => state.auth)

    const getGeneralAnalitycsTicketService = async ({ setLoading, date_begin, date_end }) => {
        setLoading && setLoading(true)
        const res = await getGeneralAnalitycsTicket({ token: idToken, company_id: currentRol, date_begin, date_end })
        const status = verifyResponse({ dataResponse: res.data, statusResponse: res.status, showToast: false })
        if (!status) {
            setLoading && setLoading(false)
            throw new Error('Error in getGeneralAnalitycsTicketService')
        }
        return res.data.data
    }

    return {
        getGeneralAnalitycsTicketService
    }
}

export default useTicketsAnalitycsServices
