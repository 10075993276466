import { useEffect, useState } from "react"
import useTicketsAnalitycsServices from "./useTicketsAnalitycsServices"
import moment from "moment"

const useTicketsAnalitycs = () => {
    const { getGeneralAnalitycsTicketService } = useTicketsAnalitycsServices()
    const [analitycsTicketData, setAnalitycsTicketData] = useState()
    const [currentDates, setCurrentDates] = useState([moment().startOf('month'), moment()])
    const [loadingTicketAnalitycs, setLoadingTicketAnalitycs] = useState(true)

    const getInitialData = async ({ date_begin, date_end }) => {
        const res = await getGeneralAnalitycsTicketService({ setLoading: setLoadingTicketAnalitycs, date_begin, date_end })
        setAnalitycsTicketData(res)
        setLoadingTicketAnalitycs(false)
    }

    const handleOnChangeDatePicker = async (value) => {
        const date_begin = value?.[0]?.clone() ?? moment().startOf('day').startOf('month')
        const date_end = value?.[1]?.clone() ?? moment()
        setCurrentDates([date_begin, date_end])
        await getInitialData({ date_begin: date_begin?.format('YYYY-MM-DD'), date_end: date_end?.format('YYYY-MM-DD') })
    }

    useEffect(() => {
        getInitialData({ date_begin: currentDates[0]?.format('YYYY-MM-DD'), date_end: currentDates[1]?.format('YYYY-MM-DD') })
    }, [])

    return {
        analitycsTicketData,
        currentDates,
        loadingTicketAnalitycs,
        handleOnChangeDatePicker
    }
}

export default useTicketsAnalitycs
