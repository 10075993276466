import { Button, Col, Row } from "antd"
import { useContext, useEffect, useRef, useState } from "react"
import { DetailTicketContext } from "../../../../context/DetailTicketContext"
import searchIcon from '../../../../../../../assets/icons/search.svg'
import { CustomInput } from "../../../../../../UI/CustomInput"

const EditSupplyBody = () => {
  const { inventoryTicket, supplies, setSupplies, setCurrentBody } = useContext(DetailTicketContext)

  const [inventoryStockData, setInventoryStockData] = useState([])
  const [searchingItem, setSearchingItem] = useState(false)
  const initialInvetoryStockData = useRef([])

  // Function to merge arrays from the dynamic structure
  const mergeArrays = (data) => {
    // Extract supply arrays and flatten them
    let supplyArrays = Object.values(data.supplies).flat()
    // Combine both arrays
    let mergedArray = [...supplyArrays]

    return mergedArray
  }

  const handleSearchLocalInventory = (e) => {
    const value = e.target.value
    if (!value) {
      //setInventoryStockData(initialInvetoryStockData.current)
      setSearchingItem(null)
    }
    //useDebounce!
    setSearchingItem(value)
  }

  useEffect(() => {
    const mergedData = mergeArrays(inventoryTicket).map(mergeItem => ({ ...mergeItem, quantity: supplies.find(supply => supply.supplyId === mergeItem.id)?.quantity ?? 0 }));
    if (!searchingItem) setInventoryStockData(mergedData)
    else {
      const filteredData = mergedData.filter(item => item?.name?.toLowerCase().includes(searchingItem?.toLowerCase()))
      setInventoryStockData(filteredData)
    }
    initialInvetoryStockData.current = mergedData
  }, [supplies, inventoryTicket, searchingItem])

  return (
    <Row>
      <Col span={24}>
        <CustomInput onChange={(e) => handleSearchLocalInventory(e)} placeholder={'Buscar'} suffixIcon={searchIcon} />
        <Row className="supplies-equipments-list-container">
          {inventoryStockData?.map((inventoryData, index) => <InventoryOption setSupplies={setSupplies} key={index} inventoryData={inventoryData} />)}
        </Row>

        <Row align="middle" justify="center">
          <Button
            type="text"
            htmlType="button"
            onClick={
              () => setCurrentBody('mainBody')
            }
            className="btn-standard-green"
          >
            <span>GUARDAR</span>
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

const InventoryOption = ({ inventoryData, setSupplies }) => {
  const handleOnChangeQuantityItem = (quantity) => {
    setSupplies(prev => {
      const currentSupplies = [...prev]

      if (!quantity) {
        const newSupplies = currentSupplies.filter(supply => supply.supplyId !== inventoryData?.id)
        return newSupplies
      }
      const newSupply = {
        ...inventoryData,
        supplyId: inventoryData?.id,
        quantity
      }

      const supplyIndex = currentSupplies.findIndex(supply => supply.supplyId === inventoryData?.id)
      if (supplyIndex !== -1) {
        currentSupplies[supplyIndex] = { ...newSupply, active: true }
      } else {
        //Generar un id aleatorio
        currentSupplies.push({ ...newSupply, active: true, id: crypto.randomUUID() })
      }
      return currentSupplies
    })
  }
  return (
    <Row justify="space-between" style={{ width: '100%' }}>
      <Col span={20}>
        <p>
          {inventoryData?.name}
        </p>
      </Col>

      <Col span={4}>
        <Row align="middle" justify="center" className="inventory-count-container">
          <Col span={8}>
            <Button
              type="text"
              htmlType="button"
              shape="circle"
              onClick={() => handleOnChangeQuantityItem(inventoryData?.quantity - 1)}
              disabled={!inventoryData?.quantity}
            >
              -
            </Button>
          </Col>
          <Col span={8}>
            <span>
              {inventoryData?.quantity}
            </span>
          </Col>
          <Col span={8}>
            <Button
              type="text"
              htmlType="button"
              shape="circle"
              disabled={inventoryData?.quantity >= inventoryData?.stock}
              onClick={() => handleOnChangeQuantityItem(inventoryData?.quantity + 1)}
            >
              +
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default EditSupplyBody