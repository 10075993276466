import { Button, Select } from "antd";

import { CloseCircleOutlined } from "@ant-design/icons";
import { useSelect } from "../hooks/useCustomSelect";

export const CustomSelect = ({
  id, mode, size = "middle", value, dataName, onChange, onSelect, onSearch, defaultValue, suffixIcon, placeholder,
  onPopupScroll = () => { }, options = [], width = "100%", disabled, loading, allowClear, showSearch = true,
  className, propsFilter, filters = true, filterSorter = true, service, isAsync = false, setCustomData,
  customData, itemsCount, delaySearch, externalData, extraItem, otherParams, maxWidth, svgIcon, maxTagCount,
}) => {
  
  const { onMoreSelectItems, onSearchItems, selectLoading, customSelectData } = useSelect({ service, setCustomData, itemsCount, delaySearch, externalData, extraItem, otherParams })

  const selectOptions = [
    ...options?.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
      disabled: item?.disabled || null,
      selected: item?.selected || null,
    })),
  ];
  //Funcion que aplica solo en las tablas de antDesign para los filtros internos que requieran select
  const onChangeTableFilter = (data) => {
    //Llamar aqui al servicio para buscar datos?
    propsFilter.setSelectedKeys([data]);
    propsFilter.handleSearch([data], propsFilter.confirm);
  };

  const onClearFilter = () => {
    propsFilter.clearFilters &&
      propsFilter.handleReset(propsFilter.confirm, propsFilter.clearFilters);
  };

  const clearFilterTable = () => {
    return (
      <Button
        className="temp-tables__filter__icon select-filter-icon"
        onClick={propsFilter.selectedKeys !== "" ? onClearFilter : null}
        icon={
          propsFilter.selectedKeys !== "" ? (
            <CloseCircleOutlined />
          ) : (
            <img src={suffixIcon} alt="suffix-icon" />
          )
        }
        size="small"
      />
    );
  };

  return (
    <>
      <Select
        id={id}
        mode={mode}
        size={size}
        onPopupScroll={(e) => isAsync ? onMoreSelectItems({ e }) : onPopupScroll({ e, dataName })}
        onSearch={showSearch ? (value) => isAsync ? onSearchItems({ value }) : (onSearch ? onSearch({ value, dataName }) : undefined) : undefined}
        onClear={propsFilter ? async () => {
          propsFilter.handleReset(propsFilter.confirm, propsFilter.clearFilters)
          if (isAsync) {
            await onSearchItems({ value: '' })
          }
        } : () => { }}
        allowClear={allowClear}
        className={className || "custom-select"}
        loading={isAsync ? selectLoading : loading}
        disabled={disabled}
        value={propsFilter ? propsFilter.selectedKeys : value}
        defaultValue={propsFilter ? propsFilter.selectedKeys : defaultValue ? defaultValue : value}
        suffixIcon={
          propsFilter ? "" : suffixIcon && svgIcon ? suffixIcon : suffixIcon ? <img alt="suffix-icon" src={suffixIcon} /> : undefined
        }
        showSearch={showSearch}
        style={{
          width,
          maxWidth
        }}
        placeholder={placeholder || "Seleccionar"}
        optionFilterProp="label"
        filterOption={(input, option) =>
          filters && (option?.label.toLowerCase() ?? "").includes(input.toLowerCase()) && !option.disabled
        }
        filterSort={(optionA, optionB) =>
          filters && filterSorter && (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
        }
        options={isAsync ? (customData ? customData : customSelectData?.dataTable?.filter(item => item.value)) : selectOptions}
        onSelect={onSelect}
        onChange={propsFilter ? onChangeTableFilter : onChange}
        maxTagCount={maxTagCount}
      />
      {propsFilter && clearFilterTable()}
    </>
  );
};