import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const createNewCategory = async (formData) => {
  try {
    const response = await axiosCreate.post('inventory/subcategory', formData, { timeout: 20000 })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default createNewCategory