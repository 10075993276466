import { axiosCreate, API_URL } from "../../../../config/index";
import { verifyBodyResponse } from "../../../helpers/verifyBodyResponse";

const ticketPath = "ticket/analytics/type/service"

//Endpoint de topia que permite recuperar las estadisticas de tiempo promedio para los tickets de clientes* 
export const getGeneralAnalitycsTicket = async ({
    token,
    company_id,
    date_begin,
    date_end
}) => {
    try {
        const params = {
            company_id,
            date_begin,
            date_end
        };

        const response = await axiosCreate.get(`${API_URL}${ticketPath}`, {
            params, timeout: 15000, headers: {
                Authorization: `Bearer ${token}`
            }
        });

        return verifyBodyResponse(response, true)
    } catch (error) {
        return verifyBodyResponse(error, false)
    }
};