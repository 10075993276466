
const StatisticsCard = ({ metric = { label: '', quantity: 0 } }) => {
    return (
        <li className="tickets-analitycs-metrics-item">
            <div className="tickets-analitycs-metrics-item-container">
                <small>{metric.label}</small>
                <span>{metric.quantity}</span>
            </div>
        </li>
    )
}

export default StatisticsCard
