import moment from "moment";

export const calculateTotalTime = (entries) => {
  let totalMinutes = 0;

  entries.forEach(entry => {
    const startTime = moment(`${entry.date?.format('YYYY-MM-DD')} ${entry.time_begin?.format('HH:mm')}`);
    const endTime = moment(`${entry.date?.format('YYYY-MM-DD')} ${entry.time_end?.format('HH:mm')}`);

    // Calcula la diferencia en minutos
    const diffMinutes = endTime.diff(startTime, 'minute');
    totalMinutes += diffMinutes;
  });

  // Conversión del total a minutos, horas o días
  if (totalMinutes < 60) {
    return `${totalMinutes} minutos`;
  } else if (totalMinutes < 1440) { // Menor a 24 horas
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours} horas ${minutes ? `y ${minutes} minutos` : ''}`;
  } else { // Mayor o igual a 24 horas
    const days = Math.floor(totalMinutes / 1440);
    const remainingMinutes = totalMinutes % 1440;
    const hours = Math.floor(remainingMinutes / 60);
    const minutes = remainingMinutes % 60;
    return `${days} días, ${hours} horas y ${minutes} minutos`;
  }
};