import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const deleteEquitment = async (id) => {
  try {
    const response = await axiosCreate.delete(`inventory/equipments/${id}`, { timeout: 20000 })
    
    return verifyBodyResponse(response, true)
  } catch (errorResponse) {
    return verifyBodyResponse(errorResponse, false)
  }
}

export default deleteEquitment
