import moment from "moment"
import { useEffect, useState } from "react"
import useBookings from "../hooks/useBookings"
import { CustomSpin } from "../../../UI/CustomSpin"
import { Button, Col, Form, Image, Row } from "antd"
import { CustomInput } from "../../../UI/CustomInput"
import CustomCalendar from "../../../UI/CustomCalendar"
import { CustomSelect } from "../../../UI/CustomSelect"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { setCurrentBookingId, setFiltersCalendar } from "../../../../store/bookings/bookingsSlice"
import { LeftOutlined, RightOutlined, SearchOutlined } from "@ant-design/icons"

// images or files
import reloadIcon from '../../../../assets/icons/Generals/reload-icon.svg'

const CalendarBookings = ({ handleOpenCloseModal }) => {
  const [form] = Form.useForm();

  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)

  const { getAmenitiesBySearch, getStatusByCalendar, getCalendarBookings, getDetailBooking } = useBookings()
  const { bookingsTableData, loadingReserves, filtersCalendar, statusBookingsCalendar } = useAppSelector(state => state.bookings)

  useEffect(() => {
    getInitialData()
  }, [])
  
  const getInitialData = async () => {
    setLoading(true)

    const startDayMonth = moment()?.date(1)?.format('YYYY-MM-DD')
    const lastDayMonth = moment()?.date(31)?.format('YYYY-MM-DD')

    await getCalendarBookings({ ...filtersCalendar, date_begin: startDayMonth, date_end: lastDayMonth })
    await getStatusByCalendar()
    
    dispatch(setFiltersCalendar({ ...filtersCalendar, date_begin: startDayMonth, date_end: lastDayMonth }))

    setLoading(false)
  }

  const statusCalendarToSelect = statusBookingsCalendar?.map((statusData) => ({ id: statusData?.reservation_status_id, name: statusData?.name }))

  const onHeaderRender = ({ value, onChange }) => {
    const prevMonth = async () => {
      onChange(value?.clone()?.subtract(1, 'M'))

      const firstMonthDay = value?.clone()?.subtract(1, 'M')?.startOf('month')?.format('YYYY-MM-DD')
      const lastMonthDay = value?.clone()?.subtract(1, 'M')?.endOf('month')?.format('YYYY-MM-DD')

      await getCalendarBookings({ ...filtersCalendar, date_begin: firstMonthDay, date_end: lastMonthDay })

      dispatch(setFiltersCalendar({ ...filtersCalendar, date_begin: firstMonthDay, date_end: lastMonthDay }))
    }

    const nextMonth = async () => {
      onChange(value?.clone()?.add(1, 'M'))

      const firstMonthDay = value?.clone()?.add(1, 'M')?.startOf('month')?.format('YYYY-MM-DD')
      const lastMonthDay = value?.clone()?.add(1, 'M')?.endOf('month')?.format('YYYY-MM-DD')

      await getCalendarBookings({ ...filtersCalendar, date_begin: firstMonthDay, date_end: lastMonthDay })

      dispatch(setFiltersCalendar({ ...filtersCalendar, date_begin: firstMonthDay, date_end: lastMonthDay }))
    }

    const handleOnChangeAmenities = async (value) => {
      if (!value) {
        await getCalendarBookings({
          ...filtersCalendar,
          place_id: ''
        })
        dispatch(setFiltersCalendar({ ...filtersCalendar, place_id: '' }))
        return
      }
  
      await getCalendarBookings({
        ...filtersCalendar,
        place_id: value,
      })
      dispatch(setFiltersCalendar({ ...filtersCalendar, place_id: value }))
    }
  
    const handleOnChangeUsers = async (value) => {
      if (!value) {
        await getCalendarBookings({
          ...filtersCalendar,
          find_value: '',
        })
        dispatch(setFiltersCalendar({ ...filtersCalendar, find_value: '' }))
  
        return
      }
  
      await getCalendarBookings({
        ...filtersCalendar,
        find_value: value.target.value,
      })
      dispatch(setFiltersCalendar({ ...filtersCalendar, find_value: value.target.value }))
    }

    const handleOnChangeStatus = async (value) => {
      if (!value) {
        await getCalendarBookings({
          ...filtersCalendar,
          reservation_status_id: ''
        })
        dispatch(setFiltersCalendar({ ...filtersCalendar, reservation_status_id: '' }))
        return
      }
  
      await getCalendarBookings({
        ...filtersCalendar,
        reservation_status_id: value,
      })
      dispatch(setFiltersCalendar({ ...filtersCalendar, reservation_status_id: value }))
    }

    const handleOnClickCleanFilters = async () => {
      await getCalendarBookings({ ...filtersCalendar, reservation_status_id: '', place_id: '', find_value: '' })

      dispatch(setFiltersCalendar({ ...filtersCalendar, reservation_status_id: '', place_id: '', find_value: '' }))

      form.resetFields()
    }

    return (
      <header style={{ margin: 12, padding: '15px 24px' }}>
        <Row gutter={8}>
          <Col span={4}>
            <Row justify="space-between" align="middle">
              <Button
                type="link"
                htmlType="button"
                onClick={prevMonth}
                icon={<LeftOutlined style={{ color: '#626C7B' }} />}
              />

              <label style={{ fontWeight: 500, color: '#252525', fontSize: 15 }}>
                {value?.format('MMMM')?.toUpperCase()} {value?.format('YYYY')}
              </label>

              <Button
                type="link"
                htmlType="button"
                onClick={nextMonth}
                icon={<RightOutlined style={{ color: '#626C7B' }} />}
              />
            </Row>
          </Col>

          <Col span={16} offset={4}>
            <Form
              form={form}
              name="filtersBookingsForm"
              layout="horizontal"
            >
              <Row gutter={8}>
                <Col span={6}>
                  <Form.Item name="find_value">
                    <CustomInput
                      isComponentIcon
                      suffixIcon={<SearchOutlined />}
                      onChange={handleOnChangeUsers}
                      placeholder="Buscar solicitante"
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name="status_booking">
                    <CustomSelect
                      allowClear
                      options={statusCalendarToSelect ?? []}
                      onChange={handleOnChangeStatus}
                      placeholder='Estado'
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item name="place_id">
                    <CustomSelect
                      isAsync
                      allowClear
                      onChange={handleOnChangeAmenities}
                      placeholder='Amenidad'
                      service={getAmenitiesBySearch}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Button
                    type="text"
                    htmlType="button"
                    shape="round"
                    onClick={handleOnClickCleanFilters}
                  >
                    <Row gutter={8} align="middle">
                      <Col>
                        <Image src={reloadIcon} alt="reload-icon" />
                      </Col>

                      <Col>
                        <span>Reiniciar filtros</span>
                      </Col>
                    </Row>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </header>
    )
  }

  const onDateCellRender = (value) => {
    const currentDate = value?.format('YYYY-MM-DD')

    if (bookingsTableData?.columns) return

    const bookingsFiltered = bookingsTableData?.filter((filterData) => filterData?.date === currentDate)
    const bookingsLength = bookingsFiltered?.length

    const handleOnClickDetailReserve = async (reservation_id) => {
      dispatch(setCurrentBookingId(reservation_id))
      const response = await getDetailBooking(reservation_id)

      if (response) {
        handleOpenCloseModal()
      }
    }

    return (<ul style={{ padding: 0 }}>
      {bookingsFiltered?.map((bookingData) => (
        <li key={bookingData?.reservation_id}>
          <Row
            onClick={() => handleOnClickDetailReserve(bookingData?.reservation_id)}
            style={{ backgroundColor: bookingData?.style?.background_color, borderRadius: 8, padding: 8, margin: bookingsLength > 2 ? '3px' : '0' }}
          >
            <Col span={24}>
              <Row wrap={false}>
                <Col span={2}>
                  <span style={{ backgroundColor: bookingData?.style?.pointer_color, marginRight: 4 }} className="circle-calendar"></span>
                </Col>

                <Col span={22}>
                  <p style={{ color: '#676767', fontWeight: 400, fontSize: 10, lineHeight: 1.5 }}>
                    {bookingData?.place_name} - 
                    <span style={{ color: '#bdb6b6' }}>
                      {bookingData?.time_begin}
                    </span>
                  </p>
                </Col>
              </Row>

              {bookingsLength < 2 && <Row>
                <span style={{ color: '#252525', fontWeight: 500, fontSize: 12 }}>
                  {bookingData?.profile_place_name}
                </span>
              </Row>}
            </Col>
          </Row>
        </li>
      ))}
    </ul>)
  }

  return (
    <CustomSpin spinning={loading || loadingReserves}>
      <CustomCalendar
        headerRender={onHeaderRender}
        dateCellRender={onDateCellRender}
      />
    </CustomSpin>
  )
}

export default CalendarBookings