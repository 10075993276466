import { useContext } from "react"
import { useAppSelector } from "../../../../hooks/store"
import { getInventory } from "../../inventario/servicios"
import verifyResponse from "../../../helpers/verifyResponse"
import updateReAssignTicket from "../services/updateReAssignTicket"
import updateWorkSessions from "../services/PUT/updateWorkSessions"
import { DetailTicketContext } from "../context/DetailTicketContext"
import updateTicketsInventory from "../services/PUT/updateTicketsInventory"
import { getEmployees } from "../../checklist/adminCheck/services/general/GET/getEmployees"

const useTickets = () => {
  const { setInventoryTicket } = useContext(DetailTicketContext)
  const { idToken, currentRol } = useAppSelector(state => state.auth)

  const getAllInventory = async () => {
    const response = await getInventory({ company_id: currentRol, query: '' })

    if (response.success) {
      setInventoryTicket(response.data.data)
      return response.success
    }

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
  }

  const updateTicketsInventories = async (formData) => {
    const response = await updateTicketsInventory(formData, idToken)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Inventario actualizado con éxito' })
  }

  const updateSessionsWork = async (formData) => {
    const response = await updateWorkSessions(formData, idToken)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Jornadas de trabajo actualizaco con éxito' })
  }

  const updateEmployeeTicket = async (formData) => {
    const response = await updateReAssignTicket(formData, idToken)

    return verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Reasignacion completada con exito' })
  }

  // Funcion get utilizada para filtros de busqueda
  const getEmployeesBySearch = async ({ find_by, find_value, limit, skip }) => {
    const response = await getEmployees({ find_by: find_by, find_value: find_value, limit: limit, skip: skip, company_id: currentRol, token: idToken, isToSelect: true })
    return response
  }

  return {
    getAllInventory,
    updateSessionsWork,
    updateEmployeeTicket,
    getEmployeesBySearch,
    updateTicketsInventories,
  }
}

export default useTickets