import { Col, Row } from "antd"
import { useContext, useEffect, useState } from "react"
import CustomModal from "../../../../../UI/CustomModal"
import DetailInventoryBody from "./bodies/DetailInventoryBody"
import { DetailTicketContext } from "../../../context/DetailTicketContext"
import EditEquipmentBody from "./bodies/EditEquipmentBody"
import EditSupplyBody from "./bodies/EditSupplyBody"
import './styles.css'

const EditTicketInventoryApprovedModal = () => {
  const [loading, setLoading] = useState(false)

  const { isOpenInventoryModal, handleOpenCloseInventoryModal, currentBody, inventoryTicket, setCurrentBody, setEquipments, setSupplies } = useContext(DetailTicketContext)

  const onSubmit = async () => {
    setLoading(true)
    setLoading(false)
  }

  const verifyCurrentInventoryBody = (currentBody) => {
    let objInventoryBodies = {
      'mainBody': <DetailInventoryBody isOpenInventoryModal={isOpenInventoryModal} onSubmit={onSubmit} />,
      'editSupplyBody': <EditSupplyBody />,
      'editEquipmentBody': <EditEquipmentBody />,
      'default': <DetailInventoryBody onSubmit={onSubmit} />
    }

    let currentInventoryBody = objInventoryBodies[currentBody] || objInventoryBodies['default']
    return currentInventoryBody
  }

  useEffect(() => {
    if (!isOpenInventoryModal) {
      setSupplies([])
      setEquipments([])
    }
  }, [isOpenInventoryModal]);

  return (
    <CustomModal
      className="custom-modal-variant-2"
      width={'600px'}
      destroyOnClose
      isOpenModal={isOpenInventoryModal}
      onCancel={() => {
        setCurrentBody('mainBody')
        handleOpenCloseInventoryModal()
      }}
    >
      <Row style={{ width: '100%' }} align="middle" justify="center">
        <Col span={24}>
          <h2 style={{ fontWeight: 600, fontSize: 22, color: '#232C4A', textAlign: 'center' }}>
            INVENTARIO SOLICITADO
          </h2>

          {verifyCurrentInventoryBody(currentBody)}
        </Col>
      </Row>
    </CustomModal>
  )
}

export default EditTicketInventoryApprovedModal