import useTickets from "../hooks/useTickets"
import { Row, Button, message, Col } from "antd"
import CustomSwitch from "../../../UI/CustomSwitch"
import { CustomInput } from "../../../UI/CustomInput"
import useRedirectTo from "../../../hooks/useRedirectTo"
import useDownLoadExcel from "../hooks/useDownLoadExcel"
import CustomTable from "../../../UI/CustomTable/CustomTable"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes"

// Imagenes o archivos svg
import SvgMesagge from "../../../UI/SvgMesagge"
import { SearchOutlined } from "@ant-design/icons"
import { ReactComponent as Excel} from "../../../../assets/icons/excel.svg"
import { setIsAssignedTickets } from "../../../../store/tickets/ticketsSlice"

const StatusTicket = () => {
  const dispatch = useAppDispatch()

  const { handleRedirectTo } = useRedirectTo()
  const { getTicketExcel, isDownloadLoading } = useDownLoadExcel()
  const { roleType, changePageHandler, skipToExcel, currentPage, getTableTickets } = useTickets()
  
  const { activeFeatures } = useAppSelector(state => state.roles)
  const { data, status, activeKey, loadingChangeTabs, isAssignedTickets, loadingTable } = useAppSelector((state) => state.tickets)
  
  const { tickets, quantity, columns, limit } = data

  const tableDataFilter = Array.isArray(columns)
    ? columns.filter((item) => item.title !== "Mensajes")
    : []

  const columnsTab = [
    ...tableDataFilter,
    {
      title: `${tableDataFilter.length > 0 ? "Mensajes" : ""}`,
      dataIndex: "messages",
      align:'center',
      render: (messages) => (
        <Row justify="center">
          <SvgMesagge numOfMesagges={messages} />
        </Row>
      ),
    },
  ]

  const handleInputChange = async (event) => {
    const value = event.target.value

    const currentIdFiltered = status?.find((statusData) => statusData.code === activeKey)

    if (value.length % 2 === 0 ) {
      await getTableTickets({ statusId: currentIdFiltered?.id ?? status[0]?.id, findId: value })
    }
  }

  const handleOnChangeMyTicketsSwitch = async (event) => {
    const currentIdFiltered = status?.find((statusData) => statusData.code === activeKey)
    
    const response = await getTableTickets({ assignedTickets: event, statusId: currentIdFiltered?.id })
    
    if (response.success) {
      dispatch(setIsAssignedTickets(event))

      return
    }

    dispatch(setIsAssignedTickets(false))
  }

  const verifyActiveTab = (activeKeyTab) => {
    let objTypes = {
      'unreviewed': false,
      'reviewed': false,
      'inspection': true,
      'inProgress': true,
      'resolved': true,
      'created': true,
      'default': false
    }

    const isType = objTypes[activeKeyTab] || objTypes['default']
    
    return isType
  }
  
  return (
    <div style={{ background: "white", padding: "24px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ paddingBottom: "1.2rem" }}
      >
        <Col>
          <h3 style={{ color: "#8F9997", fontSize: 24 }}>
            TICKETS:
            <span
              style={{
                color: "var(--primary)",
                fontSize: "1.5rem",
                marginLeft: "0.5rem",
              }}
            >
              {quantity < 10 ? `0${quantity}` : quantity}
            </span>
          </h3>
        </Col>
        
        {verifyActiveTab(activeKey) ? (
          <Col>
            <Row gutter={16} align="middle">
              <Col>
                <Row gutter={16}>
                  <Col>
                    <label style={{ fontSize:14, fontWeight: 400 }}>
                      Mis tickets
                    </label>
                  </Col>

                  <Col>
                    <CustomSwitch
                      checked={isAssignedTickets}
                      onChange={handleOnChangeMyTicketsSwitch}
                      loading={loadingTable}
                      disabled={loadingTable}
                    />
                  </Col>
                </Row>
              </Col>

              {(activeKey === 'resolved') && (<>
                <Col>
                  <CustomInput
                    isComponentIcon
                    suffixIcon={<SearchOutlined />}
                    placeholder="Buscar Ticket"
                    onChange={handleInputChange}
                  />
                </Col>

                <Col>
                  <Button
                    loading={isDownloadLoading}
                    disabled={isDownloadLoading}
                    onClick={() => getTicketExcel(skipToExcel, roleType)}
                    type="link"
                    style={{ border: "none" }}
                  >
                    <Excel />
                  </Button>
                </Col>
              </>
              )}
            </Row>
          </Col>
        ) : null}
      </Row>
      
      <CustomTable
        rowClassName="row-className"
        className="custom-table"
        columns={columnsTab}
        dataSource={tickets}
        rowKey={(record) => record.id}
        loading={loadingChangeTabs}
        pagination={{
          total: quantity,
          pageSize: limit,
          onChange: async (current) => {
            await changePageHandler(current, limit)
          },
          showTotal: (total, range) => {
            return `${range[0]}-${range[1]} de ${total}`;
          },
          current: currentPage,
        }}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            if (activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_DETAIL_TICKET)?.enabled) {
              return handleRedirectTo(`/tickets/detail/${record.id}`);
            }
            message.info('No tienes permiso para ver los detalles de un ticket', 2)
          },
        })}

      />
    </div>
  );
};

export default StatusTicket;