import CustomModal from "../../../../../../UI/CustomModal"
import { Button, Col, Row } from "antd"
import { CustomDatePicker } from "../../../../../../UI/CustomDatePicker"
import CustomTimePicker from "../../../../../../UI/CustomTimePicker/CustomTimePicker"
import { ReactComponent as TrashIcon } from '../../../../../../../assets/icons/trash-icon.svg'
import { ReactComponent as AddIcon } from './assets/addDateItem.svg'
import './styles.css'
import { calculateTotalTime } from "../../../../helpers/timeHelper"
import { CustomInput } from "../../../../../../UI/CustomInput"
import useApprovementWorkSessions from "./hooks/useApprovementWorkSessions"

const config = {
  required: true,
  message: 'El campo es necesario'
}

const EditWorkSessionsApprovedModal = () => {
  const { handleAddWorkSession, handleDeleteWorkSession, handleUpdateDateTimes, onSubmitWorkSessions, handleOpenCloseWorkSessionsModal, form, Form, loadingWorkSessions, worksSessions, isOpenWorkSessionsModal, } = useApprovementWorkSessions()

  return (
    <CustomModal
      width={'700px'}
      className="custom-modal-variant-2"
      title={'EDITAR JORNADAS'}
      isOpenModal={isOpenWorkSessionsModal}
      onCancel={handleOpenCloseWorkSessionsModal}
    >
      <div className="work-sessions-container">
        <div className="work-sessions-reparation-time-container">
          <span>Tiempo estimado de reparación: </span>
          <span className="work-sessions-reparation-time-hours">{calculateTotalTime(worksSessions)}</span>
        </div>
      </div>
      <Form layout="vertical" form={form} onFinish={(values) => onSubmitWorkSessions(values)}>
        <Form.List rules={[{ required: true, message: 'Es necesaria al menos una fecha de visita' }, {
          validator: async (_, values) => {
          }
        }]} initialValue={Array(1).fill({})} name={'session'}>
          {
            (fields, { add, remove }, { errors }) => (
              <Col className='work-sessions-work-list'>
                {
                  fields.map((field, index) => (
                    <Row key={field.name} gutter={8} align='middle' justify='center'>
                      <Col span={21}>
                        <Form.Item noStyle shouldUpdate >
                          {
                            ({ getFieldValue }) => {
                              const done = getFieldValue(['session', field.name, 'done'])
                              return (
                                <Row style={{ margin: '4px 0' }} gutter={16} align="middle" justify="center">
                                  <Col span={4}>
                                    <span>Jornada {index + 1}</span>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item rules={[{ ...config }]} name={[field.name, 'date']} label={<span>Fecha</span>}>
                                      <CustomDatePicker onChange={() => handleUpdateDateTimes({ field })} disabled={done !== undefined && done === true ? true : false} placeholder={'Seleccionar fecha'} disabledBeforeCurrentDate={true} />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item rules={[{ ...config }]} name={[field.name, 'time_begin']} label={<span>Hora inicio</span>}>
                                      <CustomTimePicker onChange={() => handleUpdateDateTimes({ field })} disabled={done !== undefined && done === true ? true : false} minuteStep={5} className="custom-time-picker-variant-2" />
                                    </Form.Item>
                                  </Col>
                                  <Col span={6}>
                                    <Form.Item shouldUpdate={(prev, curr) => prev.time_begin !== curr.time_begin} noStyle>
                                      {
                                        ({ getFieldValue }) => {
                                          const time_begin = getFieldValue(['session', field.name, 'time_begin'])
                                          return (
                                            <Form.Item
                                              rules={[{ ...config }, {
                                                validator: async (_, value) => {
                                                  if (!time_begin) return Promise.reject(new Error('Falta datos'))
                                                  if (time_begin.isBefore(value)) {
                                                    return Promise.resolve()
                                                  }
                                                  return Promise.reject(new Error('La hora de finalización debe ser superior a la hora de inicio'))
                                                }
                                              }]}
                                              label={<span>Hora fin</span>} name={[field.name, 'time_end']}>
                                              <CustomTimePicker onChange={() => handleUpdateDateTimes({ field })} disabled={done !== undefined && done === true ? true : false} className="custom-time-picker-variant-2" minuteStep={5} disableBeforeDate={time_begin} showSecond={false} />
                                            </Form.Item>
                                          )
                                        }
                                      }
                                    </Form.Item>
                                  </Col>
                                </Row>
                              )
                            }
                          }
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item noStyle shouldUpdate >
                          {
                            ({ getFieldValue }) => {
                              const done = getFieldValue(['session', field.name, 'done'])
                              return index && (done === false || done === undefined) ? <Button style={{ border: 'none', boxShadow: 'none' }} icon={<TrashIcon style={{ width: 20, height: 20 }} />} onClick={() => handleDeleteWorkSession({ remove, field })} /> : <></>
                            }
                          }
                        </Form.Item>
                        {index + 1 === fields.length && <Button style={{ border: 'none', boxShadow: 'none' }} icon={<AddIcon style={{ width: 20, height: 20 }} />} onClick={() => { add(); handleAddWorkSession({ field }) }} />}
                      </Col>
                      <Form.Item noStyle name={[field.name, 'done']}>
                        <CustomInput hidden={true} />
                      </Form.Item>
                      <Form.Item noStyle name={[field.name, 'id']}>
                        <CustomInput hidden={true} />
                      </Form.Item>
                    </Row>
                  ))
                }
                <Form.ErrorList errors={errors} />
              </Col>
            )
          }
        </Form.List>
        <Row style={{ width: '100%' }} justify="center">
          <Button loading={loadingWorkSessions} htmlType="submit" className="btn-standard-green" style={{ alignSelf: 'center' }}>GUARDAR</Button>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default EditWorkSessionsApprovedModal