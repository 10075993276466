import { useContext, useEffect, useMemo, useState } from "react"
import { Button, Checkbox, Col, Row } from "antd"
import useTickets from "../../../../hooks/useTickets"
import { useAppDispatch, useAppSelector } from "../../../../../../../hooks/store"
import { DetailTicketContext } from "../../../../context/DetailTicketContext"

// imagenes o archivos
import SupplyIcon from "../../../../../../../assets/icons/Generals/SupplyIcon"
import EquipmentIcon from "../../../../../../../assets/icons/Generals/EquipmentIcon"
import { getDetailTicketById } from "../../../../../../../store/detailTicket/thunk"

const DetailInventoryBody = () => {
  const { getAllInventory, updateTicketsInventories } = useTickets()
  const dispatch = useAppDispatch()
  const { ticketById } = useAppSelector(state => state.detailTicketById)
  const { setCurrentBody, setSupplies, setEquipments, handleOpenCloseInventoryModal, supplies, equipments } = useContext(DetailTicketContext)
  const [loadingSuppliesEquiment, setLoadingSuppliesEquiment] = useState(false)

  const { TICKET_EQUIPMENTs = [], TICKET_SUPPLies = [] } = ticketById

  const suppliesToCheckbox = useMemo(() => supplies?.map((supplyData) => ({ label: `${supplyData?.quantity} ${supplyData?.Supply?.name || supplyData?.name} | ${supplyData?.Supply?.price || supplyData?.price}`, value: supplyData?.id, active: supplyData.active })), [supplies])
  const suppliesDefaultActive = suppliesToCheckbox?.filter((supply) => supply.active).map(supply => supply.value)
  const equipmentsToCheckbox = useMemo(() => equipments?.map((equipmentData) => ({ label: `${equipmentData?.quantity} ${equipmentData?.Equipment?.name || equipmentData?.name}`, value: equipmentData?.id, active: equipmentData.active })), [equipments])
  const equipmentsDefaultActive = equipmentsToCheckbox?.filter((equipment) => equipment?.active).map(equipment => equipment?.value)
  const finalQuantity = useMemo(() => Object.values(supplies).filter(item => item?.active).reduce((total, item) => total + item?.quantity * (item?.unitPrice ?? item?.price), 0), [supplies])
  const currency = ticketById?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol

  const handleOnChange = (idValues, typeValue) => {
    if (typeValue === 1) {
      //Obtengo los ids que siguen activos
      return setSupplies((prev) => {
        const currentSupplies = [...prev]
        const newSupplies = currentSupplies.map(currentSupply => ({ ...currentSupply, active: idValues?.includes(currentSupply.id) }))
        return newSupplies
      })
    }
    return setEquipments((prev) => {
      const currentEquipments = [...prev]
      const newEquipments = currentEquipments.map(currentEquipment => ({ ...currentEquipment, active: idValues?.includes(currentEquipment.id) }))
      return newEquipments
    })
  }
  
  const handleOnSubmitInventory = async () => {
    const formData = {
      ticket_id: ticketById?.id,
      equipment: equipments.filter(equipment => equipment?.active)?.map(equipment => ({ id: equipment?.equipmentId, quantity: equipment?.quantity })),
      supply: supplies.filter(supply => supply?.active)?.map(supply => ({ id: supply?.supplyId, quantity: supply?.quantity })),
    }
    setLoadingSuppliesEquiment(true)
    const res = await updateTicketsInventories(formData)
    if (res) {
      await dispatch(getDetailTicketById(formData.ticket_id))
      handleOpenCloseInventoryModal()
      setSupplies([])
      setEquipments([])
    }
    setLoadingSuppliesEquiment(false)
  }

  useEffect(() => {
    if (supplies.length || equipments.length) return
    setSupplies(TICKET_SUPPLies)
    setEquipments(TICKET_EQUIPMENTs)
  }, [TICKET_EQUIPMENTs, TICKET_SUPPLies, supplies, equipments, setEquipments, setSupplies])


  return (
    <section>
      <Row style={{ marginTop: 32 }} gutter={16} justify="center" wrap={false}>
        <Col className="inventory-checkbox-list-container inventory-divider" span={12}>
          <label style={{ fontSize: 12, fontWeight: 600 }}>
            Insumos solicitados:
          </label>

          <ul style={{ listStyle: 'none', padding: 0 }}>
            {supplies?.length > 0
              ? <Checkbox.Group
                className="inventory-checkbox-list"
                defaultValue={suppliesDefaultActive}
                options={suppliesToCheckbox}
                onChange={(value) => handleOnChange(value, 1)}
              />
              : <p style={{ fontSize: 12, fontWeight: 600 }}>No hay insumos solicitado</p>
            }
          </ul>
        </Col>

        <Col className="inventory-checkbox-list-container" span={12}>
          <label style={{ fontSize: 12, fontWeight: 600 }}>
            Equipo solicitado:
          </label>

          <ul style={{ listStyle: 'none', padding: 0 }}>
            {equipments?.length > 0
              ? <Checkbox.Group
                className="inventory-checkbox-list"
                defaultValue={equipmentsDefaultActive}
                options={equipmentsToCheckbox}
                onChange={(value) => handleOnChange(value, 2)}
              />
              : <p style={{ fontSize: 12, fontWeight: 600 }}>No hay equipo solicitado</p>
            }
          </ul>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 25 }}>
        <Col span={12}>
          <Row justify="center">
            <Button
              loading={loadingSuppliesEquiment}
              type="text"
              shape="round"
              htmlType="button"
              style={{ backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', gap: 4 }}
              onClick={async () => {
                setLoadingSuppliesEquiment(true)
                await getAllInventory()
                setLoadingSuppliesEquiment(false)
                setCurrentBody('editSupplyBody')
              }}
              icon={<SupplyIcon />}
            >
              <span style={{ color: 'var(--primary)', fontWeight: 500, fontSize: 12 }}>AGREGAR INSUMOS</span>
            </Button>
          </Row>
        </Col>

        <Col span={12}>
          <Row justify="center">
            <Button
              loading={loadingSuppliesEquiment}
              type="text"
              shape="round"
              htmlType="button"
              style={{ backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'center', gap: 4 }}
              onClick={async () => {
                setLoadingSuppliesEquiment(true)
                await getAllInventory()
                setLoadingSuppliesEquiment(false)
                setCurrentBody('editEquipmentBody')
              }
              }
              icon={<EquipmentIcon />}
            >
              <span style={{ color: 'var(--primary)', fontWeight: 500, fontSize: 12 }}>AGREGAR EQUIPO</span>
            </Button>
          </Row>
        </Col>
      </Row >

      <Row justify="center" style={{ marginBottom: 10 }}>
        <p style={{ fontSize: 12, fontWeight: 600 }}>
          Costo total {' '}
          <span style={{ fontSize: 20, fontWeight: 700 }}>
            {currency} {finalQuantity?.toFixed(2)}
          </span>
        </p>
      </Row>

      <Row justify="center">
        <Button
          type="text"
          loading={loadingSuppliesEquiment}
          shape="round"
          htmlType="button"
          onClick={() => handleOnSubmitInventory()}
          className="btn-standard-green"
          style={{ width: 250 }}
        >
          <span>GUARDAR</span>
        </Button>
      </Row>
    </section >
  )
}

export default DetailInventoryBody