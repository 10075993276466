import { useState } from "react";
import { useAppDispatch } from "../../../../../hooks/store"
import useOpenCloseModal from "../../../../hooks/useOpenCloseModal"
import { deleteSuppliesThunk } from "../../../../../store/inventory/thunks"

const useSupplyDelete = () => {
  const dispatch = useAppDispatch()

  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal();
  const [idDelete, setIdDelete] = useState({});
  const isOpenModalDeleteSupply = isOpenModal;
  
  const onCancelSupplyDelete = () => {
    handleOpenCloseModal();
  }

  const deleteSupply = (id) => {
    handleOpenCloseModal();
    setIdDelete(id)
  }

  const validationDeleteSupply = async () => {
    await dispatch(deleteSuppliesThunk(idDelete))
    handleOpenCloseModal();
  };
  return {
    isOpenModalDeleteSupply,
    onCancelSupplyDelete,
    deleteSupply,
    validationDeleteSupply,
  };
};

export default useSupplyDelete;
