import { Button, Col, Row } from "antd"
import { useContext, useEffect, useRef, useState } from "react"
import { DetailTicketContext } from "../../../../context/DetailTicketContext"
import searchIcon from '../../../../../../../assets/icons/search.svg'
import { CustomInput } from "../../../../../../UI/CustomInput"

const EditEquipmentBody = () => {
  const { inventoryTicket, equipments, setEquipments, setCurrentBody } = useContext(DetailTicketContext)

  const [inventoryStockData, setInventoryStockData] = useState([])
  const [searchingItem, setSearchingItem] = useState(false)
  const initialInvetoryStockData = useRef([])

  // Function to merge arrays from the dynamic structure
  const mergeArrays = (data) => {
    // Extract equipment arrays and flatten them
    let equipmentArrays = Object.values(data.equipments).flat()

    let mergedArray = [...equipmentArrays]

    return mergedArray
  }

  const handleSearchLocalInventory = (e) => {
    const value = e.target.value
    if (!value) {
      //setInventoryStockData(initialInvetoryStockData.current)
      setSearchingItem(null)
    }
    //useDebounce!
    setSearchingItem(value)
  }

  useEffect(() => {
    const mergedData = mergeArrays(inventoryTicket).map(mergeItem => ({ ...mergeItem, quantity: equipments.find(equipment => equipment.equipmentId === mergeItem.id)?.quantity ?? 0 }));
    if (!searchingItem) setInventoryStockData(mergedData)
    else {
      const filteredData = mergedData.filter(item => item?.name?.toLowerCase().includes(searchingItem?.toLowerCase()))
      setInventoryStockData(filteredData)
    }
    initialInvetoryStockData.current = mergedData
  }, [equipments, inventoryTicket, searchingItem])


  return (
    <Row>
      <Col span={24}>
        <CustomInput onChange={(e) => handleSearchLocalInventory(e)} placeholder={'Buscar'} suffixIcon={searchIcon} />
        <Row className="supplies-equipments-list-container">
          {inventoryStockData?.map((inventoryData, index) => <InventoryOption setEquipments={setEquipments} key={index} inventoryData={inventoryData} />)}
        </Row>

        <Row align="middle" justify="center">
          <Button
            type="text"
            htmlType="button"
            onClick={
              () => setCurrentBody('mainBody')
            }
            className="btn-standard-green"
          >
            <span>GUARDAR</span>
          </Button>
        </Row>
      </Col>
    </Row>
  )
}

const InventoryOption = ({ inventoryData, setEquipments }) => {
  const handleOnChangeQuantityItem = (quantity) => {
    setEquipments(prev => {
      const currentEquipments = [...prev]

      if (!quantity) {
        const newEquipments = currentEquipments.filter(equipment => equipment.equipmentId !== inventoryData?.id)
        return newEquipments
      }
      const newEquipment = {
        ...inventoryData,
        equipmentId: inventoryData?.id,
        quantity
      }

      const supplyIndex = currentEquipments.findIndex(equipment => equipment.equipmentId === inventoryData?.id)
      if (supplyIndex !== -1) {
        currentEquipments[supplyIndex] = { ...newEquipment, active: true, }
      } else {
        //Generar un id aleatorio
        currentEquipments.push({ ...newEquipment, active: true, id: crypto.randomUUID() })
      }
      return currentEquipments
    })
  }
  return (
    <Row justify="space-between" style={{ width: '100%' }}>
      <Col span={20}>
        <p>
          {inventoryData?.name}
        </p>
      </Col>

      <Col span={4}>
        <Row align="middle" justify="center" className="inventory-count-container">
          <Col span={8}>
            <Button
              type="text"
              htmlType="button"
              shape="circle"
              onClick={() => handleOnChangeQuantityItem(inventoryData?.quantity - 1)}
              disabled={!inventoryData?.quantity}
            >
              -
            </Button>
          </Col>
          <Col span={8}>
            <span>
              {inventoryData?.quantity}
            </span>
          </Col>
          <Col span={8}>
            <Button
              type="text"
              htmlType="button"
              shape="circle"
              disabled={inventoryData?.quantity >= inventoryData?.stock}
              onClick={() => handleOnChangeQuantityItem(inventoryData?.quantity + 1)}
            >
              +
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default EditEquipmentBody