import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const getAllCategories = async ({ company_id }) => {
  try {
    const params = {
      company_id
    }

    const response = await axiosCreate.get('inventory/subcategory', { timeout: 20000, params })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getAllCategories