import { setInventory } from "./inventorySlice"
import verifyResponse from "../../topia/helpers/verifyResponse"
import { getAllInventoryCategories } from "./inventoryCategorySlice"
import {
  getInventory, deleteCategory, deleteEquitment, deleteSupplies, getAllCategories, createNewCategory,
  updateCategory, createNewEquipment, updateEquipments, createNewSupplies, updateSupplies
} from "../../topia/pages/inventario/servicios"

//THUNKS DE INVENTARIOS
export const setInventoryThunk = (query = "", type) => async (dispatch, getState) => {
  const { currentRol } = getState().auth

  const response = await getInventory({ query, skip: 0, limit: 50, company_id: currentRol, type })

  if (response.success) {
    dispatch(setInventory(response.data))

    return
  }
  
  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
};

//THUNKS DE CATEGORIAS
export const getAllCategoriesThunk = () => async (dispatch, getState) => {
  const { currentRol } = getState().auth

  const response = await getAllCategories({ company_id: currentRol })

  if (response.success) {
    dispatch(getAllInventoryCategories(response.data))

    return
  }

  return verifyResponse({ dataResponse: response.data, statusResponse: response.status })
}

export const addNewCategory = (formData) => async (dispatch, getState) => {
  const { currentRol } = getState().auth
  const newData = { ...formData, company_id: currentRol }
  
  const response = await createNewCategory(newData)

  if (response.success) {
    dispatch(getAllCategoriesThunk())
  }

  verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Categoría creada con éxito'  })

  return response.data
}

export const editCategoryThunk = (id, formData) => async (dispatch) => {
  const response = await updateCategory(id, formData)

  if (response.success) {
    dispatch(getAllCategoriesThunk())
  }

  verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Categoría editada con éxito' })

  return response.data
}

export const deleteCategoryThunk = (id) => async (dispatch) => {
  const { data, status, success } = await deleteCategory(id)
  
  if (success) {
    dispatch(getAllCategoriesThunk(data))
  }

  verifyResponse({ dataResponse: data, statusResponse: status, message: 'Categoría eliminada con éxito' })

  return { data, success, status }
}

//THUNKS DE EQUIPOS
export const addNewEquipmentsThunk = (data) => async (dispatch, getState) => {
  const { currentRol } = getState().auth

  const newData = { ...data, company_id: currentRol }

  const response = await createNewEquipment(newData)

  if (response.success) {
    dispatch(setInventoryThunk())
  }

  verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Equipo agregado con éxito' })
  
  return response
}

export const deleteEquipmentsThunk = (id) => async (dispatch) => {
  const { data, status, success } = await deleteEquitment(id)

  if (success) {
    dispatch(setInventoryThunk(data))
  }

  verifyResponse({ dataResponse: data, statusResponse: status, message: "Equipo eliminado con éxito"})
  
  return { data, success, status }
}

export const editEquipmentsThunk = (id, formData) => async (dispatch) => {
  const response = await updateEquipments(id, formData)

  if (response.success) {
    dispatch(setInventoryThunk())
  }

  verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Equipo editado con éxito' })

  return response
}

//THUNKS DE INSUMOS

export const addNewSuppliesThunk = (formData) => async (dispatch, getState) => {
  const { currentRol } = getState().auth

  const newData = { ...formData, company_id: currentRol }

  const response = await createNewSupplies(newData)

  if (response.success) {
    dispatch(setInventoryThunk())
  }

  verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Insumo agregado con éxito' })
  
  return response
}

export const deleteSuppliesThunk = (id) => async (dispatch) => {
  const { data, status, success } = await deleteSupplies(id)

  if (success) {
    dispatch(setInventoryThunk(data))
  }
  
  verifyResponse({ dataResponse: data, statusResponse: status, message: "Insumo eliminado con éxito"})
  
  return { data, success, status }
}

export const editSuppliesThunk = (id, formData) => async (dispatch) => {
  const response = await updateSupplies(id, formData)

  if (response.success) {
    dispatch(setInventoryThunk())
  }

  verifyResponse({ dataResponse: response.data, statusResponse: response.status, message: 'Suplemento editado con éxito' })

  return response
}
