import { Button, Col, Row } from "antd"
import './styles.css'
import { ReactComponent as SmallPDFIcon } from '../../../../../assets/icons/Generals/small-pdf-icon.svg'
import { CustomNavigate } from "../../../../UI/CustomNavigate"
import { CustomDatePicker } from "../../../../UI/CustomDatePicker"
import useTicketsAnalitycs from "./hooks/useTicketsAnalitycs"
import StatisticsCard from "./components/StatisticsCard"
import StatiticsStatusTime from "./components/StatiticsStatusTime"
import { CustomProgress } from "../../../checklist/activityMonitoring/components/ActivityMonitoring/components/CustomProgress"
import { PDFDownloadLink } from "@react-pdf/renderer"
import moment from "moment"
import { useAppSelector } from "../../../../../hooks/store"
import StatiticsPDF from "./components/StatiticsPDF/StatiticsPDF"
import { CustomSpin } from "../../../../UI/CustomSpin"

const TicketsAnalitycs = () => {
    const { analitycsTicketData, loadingTicketAnalitycs, currentDates, handleOnChangeDatePicker } = useTicketsAnalitycs()
    const { primary } = useAppSelector(state => state?.theme?.themeColors?.primary ?? { primary: '#16A07F' })

    if (!analitycsTicketData) return <CustomSpin spinning={loadingTicketAnalitycs} />

    return (
        <section className="section-wrapper">
            <CustomNavigate title={'ESTADÍSTICAS TICKETS (PROMEDIO GENERAL)'}>
                <Row style={{ gap: 16 }} align="middle">
                    <PDFDownloadLink document={<StatiticsPDF
                        currentDates={currentDates}
                        ticket_general_lifetime={analitycsTicketData.time_metrics.ticket_general_lifetime}
                        quantity_metrics={analitycsTicketData.quantity_metrics}
                        ticket_status_list={analitycsTicketData.time_metrics.ticket_status_list}
                        primary_color={primary}
                    />}
                        fileName={`Estadisticas de Ticket de clientes - ${moment().format('DD/MM/YYYY')}`}>
                        <Button
                            type="link"
                            icon={<SmallPDFIcon width={32} height={32} />}
                        />
                    </PDFDownloadLink>
                    <CustomDatePicker onChange={(value) => handleOnChangeDatePicker(value)} allowClear={true} value={currentDates} placeholder={['Inicio', 'Fin']} width="210px" type={'RANGER'} />
                </Row>
            </CustomNavigate>
            <CustomSpin spinning={loadingTicketAnalitycs}>
                <Row className="tickets-analitycs-metrics-container">
                    <ul className="tickets-analitycs-metrics-list">
                        {
                            analitycsTicketData.quantity_metrics.map((metric, index) => (
                                <StatisticsCard key={index} metric={metric} />
                            ))
                        }
                    </ul>
                </Row>
                <Row className="tickets-analitycs-time-container">
                    <Col span={12} className="tickets-analitycs-time-lifetime">
                        <span>Tiempo</span>
                        <small>Desde que se abre hasta que se cierra + tiempos promedio</small>
                        <CustomProgress height={315} width={315} type="circle" strokeColor="var(--primary)" className="tickets-analitycs-time-progress" percent={100} customFormat={analitycsTicketData.time_metrics.ticket_general_lifetime} />
                    </Col>
                    <Col span={12} className="tickets-analitycs-time-status-time-container">
                        <span>Tiempo entre estados</span>
                        <small>Es el tiempo promedio que un ticket tarda en pasar de un estado a otro</small>
                        <StatiticsStatusTime ticket_status_list={analitycsTicketData.time_metrics.ticket_status_list} />
                    </Col>
                </Row>
            </CustomSpin>
        </section>
    )
}

export default TicketsAnalitycs
