export const statiticsPDFStyles = {
    generalStyle: {
        fontFamily: 'Poppins',
        fontSize: 12
    },
    arrowContainer: {
        position: 'relative',
        width: '100%',
        height: 2,
        backgroundColor: '#16A07F', // Aquí puedes cambiar el valor por la variable de color que necesites
    },
    arrowHead: {
        position: 'absolute',
        right: 0,
        top: -4, // Ajusta este valor para alinear la flecha con la línea
        width: 10,
        height: 10,
        borderStyle: 'solid',
        borderColor: '#16A07F',
        borderWidth: 2,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        transform: 'rotate(135deg)',
    }
}