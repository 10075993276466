import React from "react";
import { Col, Row } from "antd";
import StepBar from "../UI/StepBar";
import BarOnHold from "../UI/BarOnHold";
import { useSelector } from "react-redux";
import GeneralFinalDetails from "./GeneralFinalDetails";
import GeneralTicketInformation from "./GeneralTicketInformation";
import CustomChatComponent from "../../../UI/CustomChat/CustomChatComponent";
import moment from "moment";
import 'moment/locale/es'
import { useAppSelector } from "../../../../hooks/store";
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes";
import EditTicketInventoryApprovedModal from "./modal/approvementRequeriments/EditTicketInventoryApprovedModal";
import EditWorkSessionsApprovedModal from "./modal/approvementRequeriments/approvementWorkSessions/EditWorkSessionsApprovedModal";

const ContainerDetailTicket = () => {
  moment.locale('es')
  const { ticketById } = useSelector((state) => state.detailTicketById);
  const { activeFeatures } = useAppSelector(state => state.roles)

  return (
    <section style={{ backgroundColor: "white", marginTop: "0.5rem" }}>
      <StepBar />
      <BarOnHold />
      
      <Row style={{ paddingBottom: 48 }}>
        <Col lg={12}>
          <GeneralTicketInformation />
        </Col>
        
        <Col lg={12} style={{ padding: 32 }}>
          {ticketById?.id && activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_CHAT_TICKET)?.enabled ? <CustomChatComponent urlSocket="https://topiadev.topiaapp.com" isArrow={false} /> : <></>}
          {ticketById?.TICKET_FINAL_NOTE && <GeneralFinalDetails />}
        </Col>
      </Row>

      <EditTicketInventoryApprovedModal />
      <EditWorkSessionsApprovedModal />
    </section>
  )
}

export default ContainerDetailTicket;
