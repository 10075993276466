import { useContext, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../../../../../../hooks/store"
import useTickets from "../../../../../hooks/useTickets"
import { DetailTicketContext } from "../../../../../context/DetailTicketContext"
import { Form } from "antd"
import moment from "moment"
import { getDetailTicketById } from "../../../../../../../../store/detailTicket/thunk"

const useApprovementWorkSessions = () => {
    const { ticketById } = useAppSelector(state => state.detailTicketById)
    const { updateSessionsWork } = useTickets()
    const dispatch = useAppDispatch()
    const { isOpenWorkSessionsModal, handleOpenCloseWorkSessionsModal } = useContext(DetailTicketContext)
    const { TICKET_WORK_SESSIONs } = ticketById ?? { TICKET_WORK_SESSIONs: [] }
    const [worksSessions, setWorksSessions] = useState([])
    const [loadingWorkSessions, setLoadingWorkSessions] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        if (!isOpenWorkSessionsModal || !TICKET_WORK_SESSIONs?.length) return
        const workSessions = TICKET_WORK_SESSIONs?.map(workSession => {
            const [startHour, startMinute] = workSession?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin?.split(':') ?? ['', '']
            const [endHour, endMinute] = workSession?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd?.split(':') ?? ['', '']
            return ({
                id: workSession?.EMPLOYEE_ACTIVITY_SCHEDULE?.id,
                done: workSession?.done,
                date: moment.utc(workSession?.EMPLOYEE_ACTIVITY_SCHEDULE?.date),
                time_begin: moment().startOf('day').add(startHour, 'hours').add(startMinute, 'minutes'),
                time_end: moment().startOf('day').add(endHour, 'hours').add(endMinute, 'minutes'),
            })
        })
        form.setFieldsValue({
            session: workSessions
        })
        setWorksSessions(workSessions)
    }, [TICKET_WORK_SESSIONs, form, isOpenWorkSessionsModal])

    useEffect(() => {
        if (!isOpenWorkSessionsModal) {
            form.resetFields()
            setWorksSessions([])
        }
    }, [isOpenWorkSessionsModal, form]);

    const handleAddWorkSession = ({ field }) => {
        const allSessionItems = form.getFieldValue('session')
        const actualSessionItem = form.getFieldValue(['session', field.name])
        const newSessionItem = { ...actualSessionItem, done: false }
        const newDate = actualSessionItem.date && actualSessionItem.date?.clone()?.isSameOrAfter(moment(), 'day') ? actualSessionItem.date.clone().startOf('day').add(1, 'day') : moment()
        delete newSessionItem.id
        setWorksSessions(prev => [...prev, { ...newSessionItem, date: newDate }])

        allSessionItems[field.name + 1] = { ...newSessionItem, date: newDate, id: crypto.randomUUID() }
        form.setFieldsValue({
            session: [...allSessionItems]
        })
    }

    const handleUpdateDateTimes = ({ field }) => {
        const allSessionItems = form.getFieldValue('session')
        const actualSessionItem = form.getFieldValue(['session', field.name])
        allSessionItems[field] = actualSessionItem
        setWorksSessions([...allSessionItems])
    }

    const onSubmitWorkSessions = async (values) => {
        const validIds = new Set([...worksSessions].map(entry => entry.id));

        // Mapear el primer arreglo eliminando el id si no está en el segundo arreglo
        const finalWorkSessions = values?.session.map(({ done, ...entry }) => {
            if (!validIds.has(entry.id)) {
                const { id, ...rest } = entry; // Desestructurar para eliminar 'id'
                return rest; // Retornar el objeto sin el 'id'
            }
            return entry; // Retornar el objeto sin cambios si 'id' coincide
        });
        const formData = {
            ticket_id: ticketById?.id,
            session: finalWorkSessions.map(workSession => ({
                ...workSession,
                date: workSession?.date?.format('YYYY-MM-DD'),
                time_begin: workSession?.time_begin?.format('HH:mm'),
                time_end: workSession?.time_end?.format('HH:mm'),
            }))
        }

        setLoadingWorkSessions(true)
        const res = await updateSessionsWork(formData)
        if (res) {
            await dispatch(getDetailTicketById(formData.ticket_id))
            form.resetFields()
            handleOpenCloseWorkSessionsModal()
        }
        setLoadingWorkSessions(false)
    }

    const handleDeleteWorkSession = ({ remove, field }) => {
        remove(field.name)
        const allSessionItems = form.getFieldValue('session')
        const actualSessionItem = form.getFieldValue(['session', field.name])
        allSessionItems[field] = actualSessionItem
        setWorksSessions([...allSessionItems])
    }
    return {
        handleAddWorkSession,
        handleUpdateDateTimes,
        handleDeleteWorkSession,
        onSubmitWorkSessions,
        handleOpenCloseWorkSessionsModal,
        isOpenWorkSessionsModal,
        worksSessions,
        Form, form,
        loadingWorkSessions,
    }
}

export default useApprovementWorkSessions
