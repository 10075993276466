
const StatiticsStatusTime = ({ ticket_status_list = [{ time_avarage: '', start_status: '', end_status: '' }] }) => {
    return (
        <ul className="ticket-analitycs-status-time-list">
            {
                ticket_status_list.map((ticket_item, index) => (
                    <li key={index} className="ticket-analitycs-status-time-item">
                        <span>{ticket_item.start_status}</span>
                        <div className="ticket-analitycs-status-time-item-average-time">
                            <div className="arrow-container"></div>
                            <span>{ticket_item.time_avarage}</span>
                        </div>
                        <span>{ticket_item.end_status}</span>
                    </li>
                ))
            }
        </ul>
    )
}

export default StatiticsStatusTime
