import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const updateTicketsInventory = async (formData, idToken) => {
  try {
    const response = await axiosCreate.put('/ticket/inventory', formData, {
      headers: { Authorization: `Bearer ${idToken}` }, timeout: 20000
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default updateTicketsInventory