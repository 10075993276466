import moment from "moment"
import { useContext } from "react"
import { Button, Checkbox, Row } from "antd"
import WorkDayModal from "./modal/WorkDayModal"
import { useAppSelector } from "../../../../hooks/store"
import useOpenCloseModal from "../../../hooks/useOpenCloseModal"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { DetailTicketContext } from "../context/DetailTicketContext"
import { CountDownContext } from "../../../../context/CountDownContext"
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes"
import { calculateTotalTime } from '../helpers/timeHelper'

// imagenes o archivos
import EditIcon from "../../../../assets/icons/EditIcon";

const WorkDay = () => {
  const { handleOpenCloseWorkSessionsModal } = useContext(DetailTicketContext)
  const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
  const { ticketById, flags } = useAppSelector((state) => state.detailTicketById)
  const { handleIsOpenCloseModal } = useContext(CountDownContext)
  const { activeFeatures } = useAppSelector(state => state.roles)

  const handleModalOpen = () => {
    handleOpenCloseModal();
    handleIsOpenCloseModal();
  };
  const handleModalClose = () => {
    handleIsOpenCloseModal();
    handleOpenCloseModal();
  };
  const workSessionsInspection =
    ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_WORK_SESSIONs || [];
  const workSessions = ticketById?.TICKET_WORK_SESSIONs;
  const workSessionsTemp = ticketById.TICKET_WORK_SESSIONs?.map(workSession => {
    const [startHour, startMinute] = workSession?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin?.split(':') ?? ['', '']
    const [endHour, endMinute] = workSession?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd?.split(':') ?? ['', '']
    return ({
      id: workSession?.EMPLOYEE_ACTIVITY_SCHEDULE?.id,
      date: moment.utc(workSession?.EMPLOYEE_ACTIVITY_SCHEDULE?.date),
      time_begin: moment().startOf('day').add(startHour, 'hours').add(startMinute, 'minutes'),
      time_end: moment().startOf('day').add(endHour, 'hours').add(endMinute, 'minutes'),
    })
  })

  return (
    <div>
      <Row justify="space-between">
        <p>
          Tiempo estimado de reparación: {calculateTotalTime(workSessionsTemp)}
        </p>

        {!flags?.enabledButtonUpdateApprovedRequirement ? <></> : <Button
          type="link"
          htmlType="button"
          disabled={!flags?.enabledButtonUpdateApprovedRequirement}
          shape="circle"
          onClick={() => {
            handleOpenCloseWorkSessionsModal()
          }}
          icon={<EditIcon color="var(--primary)" />}
        />}
      </Row>
      <p>Fecha(s) de mantenimiento:</p>
      {ticketById?.TICKET_INSPECTION?.TICKET_INSPECTION_STATUS?.code ===
        "accepted" ? (
        <ul>
          {workSessions?.slice()?.map((item, index) => (
            <li
              key={item?.id}
              style={{ textDecoration: item.done ? "line-through" : "none" }}
            >
              Jornada {index + 1} - (
              {moment.utc(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.date).format('DD/MM/YYYY')}{" "}
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin, 'HH:mm:ss').format('h:mm A')} {" - "}
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd, 'HH:mm:ss').format('h:mm A')})
              <Checkbox
                style={{ marginLeft: "1rem" }}
                disabled
                checked={item.done ? true : false}
              />
            </li>
          ))}
        </ul>
      ) : (
        <ul>
          {workSessionsInspection?.slice()?.map((item, index) => (
            <li
              style={{
                color:
                  item?.EMPLOYEE_ACTIVITY_SCHEDULE?.EMPLOYEE_ACTIVITY_STATUS
                    ?.code !== "pending"
                    ? "red"
                    : "black",
              }}
              key={item?.id}
            >
              Jornada {index + 1} -{" "}
              {item?.EMPLOYEE_ACTIVITY_SCHEDULE?.EMPLOYEE_ACTIVITY_STATUS
                ?.code !== "pending" && "Deshabilitada - "}
              ({moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.date).format('DD/MM/YYYY')}{" "}
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeBegin, 'HH:mm:ss').format('h:mm A')} {" - "}
              {moment(item?.EMPLOYEE_ACTIVITY_SCHEDULE?.timeEnd, 'HH:mm:ss').format('h:mm A')})
            </li>
          ))}
        </ul>
      )}
      {flags?.enabledButtonApproveInventory && (
        <div >
          <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_WORKDAY)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Editar'}`}>
            <Button
              disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_UPDATE_WORKDAY)?.enabled}
              onClick={handleModalOpen}
              style={{
                border: "none",
                position: "absolute",
                right: "1rem",
                top: "1rem",
              }}
              type="ghost"
            >
              <EditIcon color="var(--primary)" width="20px" />
            </Button>
          </CustomPopoverConfirm>
        </div>
      )}

      <WorkDayModal isOpenModal={isOpenModal} onCancel={handleModalClose} />
    </div>
  );
};

export default WorkDay;
