import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const getInventory = async ({
  query, skip = 0, limit = 50, company_id, type
}) => {
  try {
    const params = {
      query,
      skip,
      limit,
      company_id,
      type
    }

    const response = await axiosCreate.get('inventory', { timeout: 20000, params })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default getInventory