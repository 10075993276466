import { Button, Col, Row } from 'antd'
import { useContext, useState } from 'react'
import WorkDayModalTask from '../modals/WorkDayModalTask'
import useOpenCloseModal from '../../../../../../../hooks/useOpenCloseModal'
import { CreateTicketByCheckListContext } from '../../../../context/CreateTicketByCheckListContext'

// imagenes o archivos
import { PlusOutlined } from '@ant-design/icons'
import jornal from '../../../../../../../../assets/icons/workDay.svg'
import TrashIcon from '../../../../../../../../assets/icons/TrashIcon'

const MaintenanceDay = ({ setCountWorkDay }) => {
    const [workDay, setWorkDay] = useState([])
    const { isOpenModal, handleOpenCloseModal } = useOpenCloseModal()
    const { setAverageTime, setSessionSelected } = useContext(CreateTicketByCheckListContext)

    const handleDelete = (index) => {
        const newData = workDay.filter((item, i) => i !== index);
        setWorkDay(newData);
    };

    const averageTimeInHours = workDay.map((item) => {
        return {
            date: item.date,
            timeBegin: item.time[0],
            timeEnd: item.time[1]
        };
    })
    
    const timeWorkSessions = averageTimeInHours.map(({timeBegin, timeEnd}) => {return {timeBegin, timeEnd}})
        let estimatedTime = 0;
        timeWorkSessions.forEach(({timeBegin, timeEnd}) => {
        const dateTimeBegin = new Date();
        const dateTimeEnd = new Date();
        const timeBeginComponents = timeBegin.split(":").map((element) => parseInt(element));
        dateTimeBegin.setUTCHours(timeBeginComponents[0], timeBeginComponents[1],0,0)
        const timeEndComponents = timeEnd.split(":").map((element) => parseInt(element));
        dateTimeEnd.setUTCHours(timeEndComponents[0], timeEndComponents[1],0,0)
      estimatedTime += dateTimeEnd.getTime() - dateTimeBegin.getTime();
    })
        
    setAverageTime((estimatedTime)/(1000*60*60)); 
    setSessionSelected(workDay)
    setCountWorkDay(workDay.length);
    
    return (
        <div style={{marginTop:'1.5rem'}}>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
            {!workDay.length && (
                    <Row justify='center' align='middle' style={{border:'1px solid #D6D6D6', width:'100%', borderRadius:'6px', background:'#F6F6F6', paddingInline:'1.5rem', marginBottom:'1rem'}} >
                    <Col>
                        <p style={{paddingTop:'1.2rem', marginInline:'1rem'}}> No existe jornada asignada </p>
                    </Col>
                </Row>
                )
                }
                {workDay && workDay.map((item, i) => (
                    <Row key={i} justify='space-between' align='middle' style={{ border: '1px solid #D6D6D6', width: '100%', borderRadius: '6px', background: '#F6F6F6', paddingInline: '1.5rem', marginBottom: '1rem' }} >
                        <Col>
                            <img src={jornal} alt="" />
                        </Col>
                        <Col>
                            <p style={{ paddingTop: '1.2rem', marginInline: '1rem' }}>{item?.date} - {item?.time[0]} a {item?.time[1]}</p>
                        </Col>
                        <Col>
                            <Button onClick={() => handleDelete(i)} type='ghost' style={{ border: 'none',paddingTop:'0.5rem' }} >
                                <TrashIcon color="#E82A2A" width="20px" />
                            </Button>
                        </Col>
                    </Row>
                ))}
            </div>
            <Button
                icon={<PlusOutlined style={{color:'white', fontSize:'1.5rem'}} />}
                shape='circle'
                onClick={handleOpenCloseModal}
                style={{width:'36px', height:'36px', border: '1px solid #D6D6D6', background: 'var(--primary)', position: 'absolute', top:'-0.5rem', right:20}}
            />
            <WorkDayModalTask setWorkDay={setWorkDay} onCancel={handleOpenCloseModal} isOpenModal={isOpenModal} />
        </div>
    );
};

export default MaintenanceDay;
