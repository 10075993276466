import { Form } from "antd"
import { createContext, useState } from "react"

const initialState = {
  isOpenWorkSessionsModal: false, handleOpenCloseWorkSessionsModal: () => { }, workSessions: [], setWorkSessions: () => { },
  inventoryTicket: [], setInventoryTicket: () => { }, isOpenInventoryModal: false, setIsOpenInventoryModal: () => { },
  handleOpenCloseInventoryModal: () => { }, currentBody: 'mainBody', setCurrentBody: () => { }, supplies: [], setSupplies: () => { },
  equipments: [], setEquipments: () => { },
}

export const DetailTicketContext = createContext(initialState)

export const DetailTicketContextProvider = ({ children }) => {
  const [supplies, setSupplies] = useState([])
  const [equipments, setEquipments] = useState([])

  const [currentBody, setCurrentBody] = useState('mainBody')

  const [workSessions, setWorkSessions] = useState([])
  const [inventoryTicket, setInventoryTicket] = useState([])

  // Modal para el inventario del detalle de un ticket
  const [inventoryForm] = Form.useForm()
  const [isOpenInventoryModal, setIsOpenInventoryModal] = useState(false)
  const handleOpenCloseInventoryModal = () => setIsOpenInventoryModal((state) => !state)

  const [workSessionsForm] = Form.useForm()
  const [isOpenWorkSessionsModal, setIsOpenWorkSessionsModal] = useState(false)
  const handleOpenCloseWorkSessionsModal = () => setIsOpenWorkSessionsModal((state) => !state)

  const value = {
    workSessionsForm, isOpenWorkSessionsModal, handleOpenCloseWorkSessionsModal, workSessions, setWorkSessions,
    inventoryTicket, setInventoryTicket, isOpenInventoryModal, setIsOpenInventoryModal,
    handleOpenCloseInventoryModal, inventoryForm, currentBody, setCurrentBody, equipments, setEquipments,
    supplies, setSupplies,
  }

  return <DetailTicketContext.Provider value={value}>
    {children}
  </DetailTicketContext.Provider>
}