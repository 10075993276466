import { Image, Text, View } from "@react-pdf/renderer"
import CustomFilePdf from "../../../../../../UI/CustomFiles/CustomFilePdf"
import { statiticsPDFStyles } from "./styles";
import integroLogo1 from '../../../../../../../assets/logos/integroLogo-variant-1.png'

// Componente que representa el contenedor con la flecha
const ArrowComponent = ({ primary_color }) => (
    <View style={{ ...statiticsPDFStyles.arrowContainer, backgroundColor: primary_color }}>
        <View style={{ ...statiticsPDFStyles.arrowHead, borderColor: primary_color }} />
    </View>
);

const StatiticsPDF = ({ currentDates, quantity_metrics, ticket_general_lifetime, ticket_status_list, primary_color }) => {
    return (
        <CustomFilePdf size="Letter" multiplePages>
            <View style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 32, marginBottom: 48 }}>
                <Image src={integroLogo1} style={{ width: 159, height: 40, objectFit: 'cover' }} />
            </View>
            <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', paddingBottom: 40, marginBottom: 32, borderBottom: '1px solid #ABABAB' }}>
                <Text style={{ ...statiticsPDFStyles.generalStyle, fontWeight: 'semibold', fontSize: 14 }} >ESTADÍSTICAS TICKETS <Text style={{ color: '#707070' }}>(PROMEDIO GENERAL)</Text></Text>
                <Text style={{ ...statiticsPDFStyles.generalStyle, color: primary_color, fontWeight: 'semibold', fontSize: 14 }}>{currentDates?.[0]?.format('DD/MM/YYYY')} - {currentDates?.[1]?.format('DD/MM/YYYY')}</Text>
            </View>
            <View>
                <Text style={{ ...statiticsPDFStyles.generalStyle, fontWeight: 'semibold' }}>Tickets</Text>
                <View style={{ display: 'flex', gap: 8, flexDirection: 'row', marginTop: 8, marginBottom: 64 }}>
                    {
                        quantity_metrics?.map((item, index) => (
                            <View style={{ width: '100%', border: '0.7px solid #707070', height: 64, borderRadius: 6, padding: '6px 16px' }} key={index}>
                                <View style={{ padding: '0 16px', borderLeft: `2px solid ${primary_color}`, height: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    <Text style={{ ...statiticsPDFStyles.generalStyle, fontSize: 10, textOverflow: 'ellipsis', overflow: 'hidden', textTransform: 'capitalize' }}>{item?.label?.replace(/tickets?\s/i, '')}</Text>
                                    <Text style={{ ...statiticsPDFStyles.generalStyle, fontSize: 18, fontWeight: 'semibold' }}>{item?.quantity}</Text>
                                </View>
                            </View>
                        ))
                    }
                </View>
            </View>
            <View style={{ display: 'flex', flexDirection: 'row', gap: 32 }}>
                <View style={{ width: '30%' }}>
                    <Text style={{ ...statiticsPDFStyles.generalStyle, fontWeight: 'semibold', marginBottom: 8 }}>Tiempo</Text>
                    <Text style={{ ...statiticsPDFStyles.generalStyle, fontSize: 10, color: '#404040' }}>Desde que se abre hasta que se cierra + tiempos promedios</Text>
                    <View style={{ marginTop: 32, border: `9px solid ${primary_color}`, borderRadius: '100%', height: 150, width: 150, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ ...statiticsPDFStyles.generalStyle, fontWeight: 'bold', fontSize: 30, color: '#404040', textOverflow: 'ellipsis' }}>{ticket_general_lifetime}</Text>
                    </View>
                </View>
                <View style={{ width: '70%' }}>
                    <Text style={{ ...statiticsPDFStyles.generalStyle, fontWeight: 'semibold', marginBottom: 8 }}>Tiempo entre estados</Text>
                    <Text style={{ ...statiticsPDFStyles.generalStyle, fontSize: 10, color: '#404040' }}>Es el tiempo promedio que un ticket tarda en pasar de un estado a otro.</Text>
                    <View style={{ display: 'flex', flexDirection: 'column', gap: 24, marginTop: 32 }}>
                        {
                            ticket_status_list?.map((item, index) => (
                                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }} key={index}>
                                    <Text style={{ ...statiticsPDFStyles.generalStyle, width: '20%' }}>{item?.start_status}</Text>
                                    <View style={{ width: '60%', padding: '0 16px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <ArrowComponent primary_color={primary_color} />
                                        <Text style={{ ...statiticsPDFStyles.generalStyle, position: 'absolute', backgroundColor: primary_color, padding: '2px 8px', borderRadius: '16px', color: 'white', fontSize: 10 }}>
                                            {item?.time_avarage}
                                        </Text>
                                    </View>
                                    <Text style={{ ...statiticsPDFStyles.generalStyle, width: '20%' }}>
                                        {item?.end_status}
                                    </Text>
                                </View>
                            ))
                        }
                    </View>
                </View>
            </View>
            <View fixed style={{ backgroundColor: '#252525', height: '9px', position: 'absolute', top: 16, width: 560, marginHorizontal: 24 }} />
            <View fixed style={{ position: 'absolute', height: '9px', backgroundColor: '#252525', bottom: 16, width: 560, marginHorizontal: 24 }} />
        </CustomFilePdf>
    )
}

export default StatiticsPDF
