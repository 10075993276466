import moment from "moment"
import { useEffect } from "react"
import { Button, Col, Divider, Row } from "antd"
import { CustomInput } from "../../../../../../UI/CustomInput"
import { CustomSelect } from "../../../../../../UI/CustomSelect"
import { useAppSelector } from "../../../../../../../hooks/store"
import { CustomNavigate } from "../../../../../../UI/CustomNavigate"
import { CustomDatePicker } from "../../../../../../UI/CustomDatePicker"
import useCurrentLocation from "../../../../../../hooks/useCurrentLocation"
import CustomTimePicker from "../../../../../../UI/CustomTimePicker/CustomTimePicker"
import { CustomUploadFile } from "../../../../../../UI/CustomUploadFile/CustomUploadFile"
import { ReactComponent as MaintenanceDateIcon } from '../../../../assets/maintenanceDateIcon.svg'
import { useActivesMaintenance } from "../../../../hooks/useActivesMaintenance/useActivesMaintenance"
import { useNewMaintenanceReport } from "../../../../hooks/useNewMaintenanceReport/useNewMaintenanceReport"
import { useDetailMaintenanceReport } from "../../../../hooks/useDetailMaintenanceReport/useDetailMaintenanceReport"

import '../../../styles.css'

export const NewMaintenanceReport = () => {
    const {
        onSubmit, onFailedSubmit, handleChangeDate, handleChangeTimePicker, handleRedirectTo, getInitialDataProviders,
        filesList, imagesList, config, setFilesList, setImagesList, form, Form, loading,
    } = useNewMaintenanceReport()

    const { param } = useCurrentLocation()
    const { currencySymbol } = useAppSelector(state => state.auth)
    const { getAllCompanyAssetsService } = useActivesMaintenance()
    const { getInitialData } = useDetailMaintenanceReport()

    const getDataToSelect = async ({ find_by, find_value }) => {
        const response = await getAllCompanyAssetsService({ isToSelect: true, find_by, find_value, limit: 100 })

        return response
    }

    const isEditReport = param?.historyAction === 'edit-report'

    const getDetailDataEdit = async () => {
        const response = await getInitialData({ company_asset_maintenance_id: param.reportId })

        const files = response?.maintenanceReport?.COMPANY_ASSET_MAINTENANCE_ATTACHMENTs?.map((fileUrl) => ({
            uid: crypto.randomUUID(),
            name: fileUrl.name,
            status: 'done',
            url: fileUrl.url,
            size: parseFloat(fileUrl.size),
            type: ''
        }))

        const filesImg = response?.maintenanceReport?.COMPANY_ASSET_MAINTENANCE_IMAGEs?.map((imgeUrl) => ({
            uid: crypto.randomUUID(),
            name: imgeUrl.name,
            status: 'done',
            url: imgeUrl.url,
            type: 'image/',
        }))

        setFilesList(files)

        setImagesList(filesImg)

        form.setFieldsValue({
            provider_id: response?.maintenanceReport?.PROVIDER?.provider_id,
            employee_name: response?.maintenanceReport?.employee_name,
            date: moment(response?.maintenanceReport?.date),
            time_begin: moment(response?.maintenanceReport?.time_begin, 'HH:mm a'),
            time_end: moment(response?.maintenanceReport?.time_end, 'HH:mm a'),
            observations: response?.maintenanceReport?.observations,
            cost: response?.maintenanceReport?.cost,
            next_maintenance_date: moment(response?.maintenanceReport?.next_maintenance_date)
        })
    }

    useEffect(() => {
        if (isEditReport) {
            getDetailDataEdit()
        }
    }, [])

    return (
        <div className="section-wrapper">
            <CustomNavigate title={isEditReport ? 'EDITAR REPORTE DE MANTENIMIENTO' : 'NUEVO REPORTE DE MANTENIMIENTO'} />
            <div style={{ paddingBottom: 32 }}>
                <Form
                    name="reportMaintenance"
                    className="active-maintenances-form report-form"
                    form={form}
                    layout="vertical"
                    onFinish={(values) => onSubmit({ values, form })}
                    onFinishFailed={onFailedSubmit}
                >
                    {param?.activeId === 'new-report' && (
                        <>
                            <Row>
                                <Col span={24}>
                                    <label style={{ fontWeight: 500, fontSize: 14, color: '#232C4A', marginBottom: 25 }}>
                                        Seleccione los activos a los que desea crearles un reporte de manenimiento
                                    </label>

                                    <Form.Item rules={[{ ...config }]} label={'ACTIVOS'} name={'company_asset_ids'}>
                                        <CustomSelect
                                            isAsync
                                            mode='multiple'
                                            maxTagCount='responsive'
                                            service={getDataToSelect}
                                            placeholder='Seleccione multiples activos'
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider style={{ borderTop: "1px solid #969696" }} />
                        </>
                    )}

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item rules={[{ ...config }]} label={'EMPRESA PROVEEDORA DE MANTENIMIENTO'} name={'provider_id'}>
                                <CustomSelect
                                    isAsync
                                    allowClear
                                    placeholder="Selecciona un proveedor"
                                    service={getInitialDataProviders}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item rules={[{ ...config }]} label={'EMPLEADO RESPONSABLE DE SUPERVISIÓN'} name={'employee_name'}>
                                <CustomInput placeholder={'Nombre de empleado'} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item rules={[{ ...config }]} label={'FECHA DE MANTENIMIENTO'} name={'date'}>
                                <CustomDatePicker
                                    disableNonCurrentMonthAndFutureDates={true}
                                    onChange={handleChangeDate}
                                    suffixIcon={<MaintenanceDateIcon />}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item rules={[{ ...config }]} label={'HORA DE INICIO'} name={'time_begin'}>
                                        <CustomTimePicker onChange={handleChangeTimePicker} minuteStep={5} showSecond={false} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item shouldUpdate={(prev, curr) => prev.time_begin !== curr.time_begin} noStyle>
                                        {
                                            ({ getFieldValue }) => {
                                                const time_begin = getFieldValue('time_begin')
                                                return (
                                                    <Form.Item
                                                        rules={[{ ...config }, {
                                                            validator: async (_, value) => {
                                                                if (!time_begin) return Promise.reject(new Error('Falta datos'))
                                                                if (time_begin.isBefore(value)) {
                                                                    return Promise.resolve()
                                                                }
                                                                return Promise.reject(new Error('La hora de finalización debe ser superior a la hora de inicio'))
                                                            }
                                                        }]}
                                                        label={'HORA DE FINALIZACIÓN'} name={'time_end'}>
                                                        <CustomTimePicker minuteStep={5} disableBeforeDate={time_begin} showSecond={false} />
                                                    </Form.Item>
                                                )
                                            }
                                        }
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Col>
                        <Form.Item rules={[{ ...config }]} label={'NOTAS GENERALES'} name={'observations'}>
                            <CustomInput rules={[{ ...config }]} type="AREA" autoSize={{ minRows: 7, maxRows: 20 }} maxLength={700} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item rules={[{ ...config }]} label={'COSTE DEL MANTENIMIENTO'} name={'cost'}>
                                    <CustomInput type="NUMBER" className="custom-input-active-maintance-report" nullInputNumberText={null} prefix={currencySymbol} min={0} precision={2} step={0.01} maxLength={10} placeholder={'Costo del mantenimiento'} />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item shouldUpdate={(prev, curr) => prev.date !== curr.date} noStyle>
                                    {
                                        ({ getFieldValue }) => {
                                            const date = getFieldValue('next_maintenance_date')
                                            return (
                                                <Form.Item rules={[{ ...config }]} label={'PRÓXIMA FECHA DE MANTENIMIENTO'} name={'next_maintenance_date'} >
                                                    <CustomDatePicker disabledDateDependsOnStartDate={date} suffixIcon={<MaintenanceDateIcon />} />
                                                </Form.Item>
                                            )
                                        }
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Form.Item label={'REPORTE DE PROVEEDOR'} name={'files'}
                            rules={[{
                                validator: async (_, value) => {
                                    if (filesList.length) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Se requiere al meno un archivo'))
                                }
                            }]}>
                            <CustomUploadFile multipleFiles maxCount={3} maxSizeInBytes={5 * 1024 * 1024} acceptFilesFormat={'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint'} width={'100%'} height={235} minHeight={235} fileList={filesList} setFileList={setFilesList} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label={'FOTOGRAFÍAS ANEXAS'} name={'files-image'}
                            rules={[{
                                validator: async (_, value) => {
                                    if (imagesList.length) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Se requiere al meno una imagen'))
                                }
                            }]}>
                            <CustomUploadFile maxCount={10} multipleFiles verticalUpload={false} justImages width={'100%'} height={600} minHeight={235} fileList={imagesList} setFileList={setImagesList} />
                        </Form.Item>
                    </Col>
                    <Row justify="end" style={{ gap: 32 }}>
                        <Button loading={loading} className="btn-standard-white" shape="round" onClick={() => handleRedirectTo(-1)}>
                            CANCELAR
                        </Button>
                        <Button loading={loading} htmlType="submit" className="btn-standard-green" shape="round">
                            GUARDAR
                        </Button>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default NewMaintenanceReport