import { Button, Col, Row } from 'antd'
import CustomModal from '../../../../UI/CustomModal'
import { CustomInput } from '../../../../UI/CustomInput'
import { CustomSelect } from '../../../../UI/CustomSelect'
import { selectIcon } from '../../assets'
import '../styles.css'
import ProviderDateItem from './components/ProviderDateItem'
import { useEffect } from 'react'

const NewProviderAccessModal = ({ isOpenModal, onCancel, onSubmit, accessDetail, providerService, reasonVisitService, Form, form, getInitialFormData, loading, handleAddNewDateItem }) => {

  useEffect(() => {
    if (accessDetail?.edit) {
      getInitialFormData()
    }
  }, [accessDetail]);

  return (
    <CustomModal width={1020} isOpenModal={isOpenModal} onCancel={() => { onCancel({ name: 'newAccessProviderModal', visible: false }); form.resetFields() }} destroyOnClose={true} title={`${accessDetail?.edit ? 'EDITAR' : 'NUEVO'} ACCESSO A PROVEEDOR`} customTitle className='custom-modal'>
      <Form
        form={form}
        layout='vertical'
        name='accessProviderForm'
        autoComplete='off'
        onFinish={(values) => onSubmit({ values, form })}
      >
        <Row gutter={40}>
          <Col span={12}>
            <Form.Item noStyle name={'event_id'}>
              <CustomInput hidden={true} />
            </Form.Item>
            <Form.Item rules={[{ required: true, message: 'El proveedor es necesario' }]} label={<strong>NOMBRE DEL PROVEEDOR</strong>} name={'provider_id'}>
              <CustomSelect isAsync otherParams={{ sort_by: 'name', sort_order: 'asc' }} allowClear={true} extraItem={accessDetail?.provider ? { id: accessDetail?.provider?.provider_id, name: accessDetail?.provider?.provider_name } : undefined} itemsCount={25} service={providerService} suffixIcon={selectIcon} />
            </Form.Item>
            <Form.Item rules={[{ required: true, message: 'El motivo de visita es necesario' }]} label={<strong>MOTIVO DE LA VISITA</strong>} name={'event_reason_id'}>
              <CustomSelect
                isAsync
                allowClear={true}
                extraItem={accessDetail?.reason ? { id: accessDetail?.reason?.reason_id, name: accessDetail?.reason?.reason_name } : undefined}
                itemsCount={25}
                service={reasonVisitService}
                suffixIcon={selectIcon}
              />
            </Form.Item>
            <Form.Item rules={[{ required: true, message: 'El responsable de recepción es necesario' }]} label={<strong>UNIDAD O EMPLEADO RESPONSABLE DE RECEPCIÓN</strong>} name={'receiver_name'}>
              <CustomInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row style={{ gap: 64, paddingLeft: 8, marginBottom: 8, fontSize: 12 }}>
              <strong >FECHA DE VISITA</strong>
              <strong >HORA DE VISITA</strong>
            </Row>
            <Col style={{ padding: 0, maxHeight: 250, overflowY: 'auto' }}>
              <ProviderDateItem handleAddNewDateItem={handleAddNewDateItem} Form={Form} />
            </Col>
            <Form.Item name={'description'} label={<strong>DESCRIPCIÓN DE SU VISITA</strong>}>
              <CustomInput type='AREA' autoSize={{ minRows: 4 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row align='middle' justify='end' style={{ gap: 24 }}>
          <Button htmlType='reset' onClick={() => onCancel({ name: 'newAccessProviderModal', visible: false })} className='btn-standard-white' shape='round' style={{ maxWidth: 186 }}>CANCELAR</Button>
          <Button htmlType='submit' className='btn-standard-green' shape='round' style={{ maxWidth: 186 }} loading={loading}>GUARDAR</Button>
        </Row>
      </Form>
    </CustomModal>
  )
}

export default NewProviderAccessModal