import { useState, useEffect } from "react"
import {
  updateTicketCategory,
  createTicketCategory,
  createTicketSubCategory,
  deleteTicketSubCategory,
  updateTicketSubCategory,
  getAllTicketsCategoriesByCompany,
} from "../services"
import verifyResponse from "../../../../helpers/verifyResponse"
import { useAppSelector } from "../../../../hooks/store"

const useTickets = () => {
  const [categories, setCategories] = useState([])
  const [loadingCategories, setLoadingCategories] = useState(false)
  const [loadingActionsTable, setLoadingActionsTable] = useState(false)
  const currentCompanyId = useAppSelector((state) => state?.auth?.currentRol)

  useEffect(() => {
    if (currentCompanyId) {
      getAllTicketsCategories(currentCompanyId)
    }
  }, [])

  const onSaveCategory = async (formData) => {
    setLoadingActionsTable(true)

    const functionToExecute = formData.id ? updateTicketCategory : createTicketCategory
    const messageToShow = formData.id ? "Categoría actualizada con éxito" : "Categoría creada con éxito"
    const response = await functionToExecute(formData)

    setLoadingActionsTable(false)
    return verifyResponse(response.data, response.status, messageToShow)
  }

  const onSaveSubCategory = async (formData) => {
    setLoadingActionsTable(true)

    const functionToExecute = formData.id ? updateTicketSubCategory : createTicketSubCategory
    const messageToShow = formData.id ? "SubCategoría actualizada con éxito" : "SubCategoría creada con éxito"
    const response = await functionToExecute(formData)

    setLoadingActionsTable(true)

    return verifyResponse(response.data, response.status, messageToShow)
  }

  const onDeleteSubCategory = async (subCategoryId) => {
    setLoadingActionsTable(true)

    const response = await deleteTicketSubCategory(subCategoryId)

    setLoadingActionsTable(false)

    return verifyResponse(response.data, response.status, "SubCategoría eliminada con éxito")
  }

  const getAllTicketsCategories = async (companyId) => {
    setLoadingCategories(true)

    const response = await getAllTicketsCategoriesByCompany(companyId)

    if (response.success) {
      setCategories(response.data.data)
      setLoadingCategories(false)
      return true
    }

    setLoadingCategories(false)
    return verifyResponse(response.data, response.status, "")
  }

  return {
    categories,
    loadingCategories,
    loadingActionsTable,
    onSaveCategory,
    onSaveSubCategory,
    onDeleteSubCategory,
    getAllTicketsCategories,
  }
}

export default useTickets
