import { Button, Col, Divider, Image, Row } from "antd"
import CustomPopoverConfirm from "../../../UI/CustomPopoverConfirm"
import { useAppDispatch, useAppSelector } from "../../../../hooks/store"
import { resolvedTicketThunk } from "../../../../store/detailTicket/thunk"
import { TICKETS_CODES } from "../../../../constants/modules/tickets/ticketsCodes"

const GeneralFinalDetails = () => {
  const dispatch = useAppDispatch();

  const { activeFeatures } = useAppSelector(state => state.roles)
  const { ticketById, flags } = useAppSelector((state) => state.detailTicketById);

  const minutes = ticketById?.TICKET_FINAL_NOTE?.totalMinutes;
  const timeResult = (Math.round((minutes / 60) * 100) / 100)
    .toFixed(2)
    .replace(".", ":");

  const currency = ticketById?.COMPANY?.COMPANY_DETAIL?.COMPANY_CURRENCY?.symbol;

  const ticketFinished = () => {
    dispatch(
      resolvedTicketThunk(
        ticketById?.id,
        ticketById?.TICKET_EMPLOYEE?.employeeId
      )
    );
  };

  return (
    <div>
      <Row justify="center" style={{ padding: "1rem", marginTop: "2rem" }}>
        <div
          style={{
            width: "42.375rem",
            background: "var(--primaryminus4)",
            padding: "2rem",
            borderRadius: "10px",
            border: '0.6mm dashed #afafaf'
          }}
        >
          <Row>
            <Col span={12}>
              <p style={{ fontWeight: "bold" }}>Número de Jornadas</p>
              {ticketById?.TICKET_FINAL_NOTE?.totalWorkSessions}
              <p style={{ fontWeight: "bold" }}>Tiempo de realización</p>
              <p>
                {timeResult} {minutes < 60 ? "minutos" : "horas"}
              </p>
            </Col>
            <Col span={12}>
              <p style={{ fontWeight: "bold" }}>Notas Finales</p>
              <p style={{ fontSize: "12px" }}>
                {ticketById?.TICKET_FINAL_NOTE?.description}
              </p>
            </Col>
          </Row>
          <Divider className="detail-ticket__divider" />
          <Row>
            <Col span={12}>
              <p style={{ fontWeight: "bold" }}>Costo Total</p>
              <Row align="middle" style={{ height: "100%" }}>
                <p style={{ fontSize: "36px", fontWeight: "bold" }}>
                  {" "}
                  {currency}{" "}
                  {ticketById?.TICKET_FINAL_NOTE?.totalCost.toFixed(2)}{" "}
                </p>
              </Row>
            </Col>
            <Col span={12}>
              <p style={{ fontWeight: "bold" }}>Firma del cliente</p>
              <Row
                justify="center"
                align="middle"
                style={{
                  width: "100%",
                  height: "8rem",
                  background: "white",
                  borderRadius: "10px",
                }}
              >
                <Image
                  src={ticketById?.TICKET_FINAL_NOTE?.signImage}
                  alt="sign"
                  style={{ maxHeight: "80%", maxWidth: "70%" }}
                />
              </Row>
            </Col>
          </Row>
        </div>
      </Row>
      <div>
        {flags?.enabledButtonResolve ? (
          <Row justify="center">
            <CustomPopoverConfirm trigger={'hover'} content={`${!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_FINISH_TICKET)?.enabled ? 'No tienes permiso para realizar esta acción' : 'Finalizar Ticket'}`}>
              <Button
                disabled={!activeFeatures?.find(feature => feature.feature_code === TICKETS_CODES.TICKETS_FINISH_TICKET)?.enabled}
                onClick={ticketFinished}
                className="btn-standard-green"
                shape="round"
                type="ghost"
              >
                <span>FINALIZAR TICKET</span>
              </Button>
            </CustomPopoverConfirm>
          </Row>
        ) : <></>}
      </div>
    </div>
  );
};

export default GeneralFinalDetails;
