import { axiosCreate } from "../../../../../config/index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const updateEquipments = async (id, formData) => {
  try {
    const response = await axiosCreate.put(`inventory/equipments/${id}`, formData, { timeout: 20000 })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default updateEquipments