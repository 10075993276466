import { useState } from "react";
import { getAllEmployee, getDepartments, disabledEmployee, createNewEmployeeService, getEmployeeInfoService, editEmployeeService, activateEmployeeService } from "../services";
import { getNewColumns } from "../helpers/getNewColumns";
import { fireEmployeeService } from "../services";
import verifyResponse from "../../../../helpers/verifyResponse";

const useEmployee = () => {
  const [{ columns, dataSource }, setEmployees] = useState([]);
  const [{ limit, quantity }, setEmployeesMetada] = useState({})
  const [employeeSelected, setEmployeeSelected] = useState({})
  const [currentPage, setCurrentPage] = useState(1);
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false)

  const getEmployees = async (companyId, departmentId, skip, customSelectData) => {
    setLoading(true);
    const res = await getAllEmployee({ departmentId, skip, companyId });
    setLoading(false);
    if (customSelectData && res.success) return res;
    if (res.success) {
      setEmployees(res.data.data)
      setEmployeesMetada(res.data.metadata)
    };
  };

  const getEmployeesSelect = async ({ find_by, find_value, sort_by, sort_order, limit, skip, setLoading, customSelectData, departmentId, companyId }) => {
    setLoading(true)
    const res = await getAllEmployee({ departmentId, skip, companyId, find_by, find_value, sort_by, sort_order, limit })
    const status = verifyResponse(res.data.data, res.data.status)
    if (!status) {
      setLoading(false)
      throw new Error('Error in getEmployeesSelect')
    }
    if (customSelectData) return res
  }

  const getEmployeeInfo = async (employeeId, sectionId) => {
    const res = await getEmployeeInfoService(employeeId)
    if (res.success) setEmployeeSelected({ ...res.data.data, sectionId })
  }

  const clearEmployeeSelected = () => {
    setEmployeeSelected({})
  }

  const getAllDepartments = async (companyId) => {
    const res = await getDepartments(companyId);

    if (res.success) setDepartments(res.data.data);
  };

  const incapacitateEmployee = async (data) => {
    const res = await disabledEmployee(data);
    return onResponse(res, 'Empleado incapacitado')
  };

  const fireEmployee = async (data) => {
    const res = await fireEmployeeService(data);
    return onResponse(res, 'Empleado dado de baja')
  };

  const onSaveEmployees = async (data, id) => {
    const functionToExecute = id ? editEmployeeService : createNewEmployeeService;
    const messageToShow = id ? 'Empleado editado exitosamente' : 'Empleado creado exitosamente'
    const res = await functionToExecute(data);

    return onResponse(res, messageToShow)
  };


  const activeEmployee = async (data) => {
    const res = await activateEmployeeService(data)
    return onResponse(res, 'Empleado activado exitosamente')
  }

  const onResponse = (res, message) => {
    if (res.success) return verifyResponse(res.data, res.status, message)
    return verifyResponse(res.error.data, res.error.status, '')
  }

  const changePageHandler = (value, departmentSelected, companyId) => {
    const pageValue = value || currentPage;
    const newSkip = pageValue * limit - limit;
    setCurrentPage(pageValue);
    getEmployees(companyId, departmentSelected, newSkip);
  };

  const columnsTable = getNewColumns(columns);

  return {
    columnsTable,
    dataSource,
    getEmployees,
    getEmployeesSelect,
    employeeSelected,
    getEmployeeInfo,
    getAllDepartments,
    departments,
    incapacitateEmployee,
    fireEmployee,
    clearEmployeeSelected,
    setEmployeeSelected,
    quantity,
    limit,
    loading,
    currentPage,
    changePageHandler,
    activeEmployee,
    onSaveEmployees
  };
};

export default useEmployee;
