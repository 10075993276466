import { axiosCreate } from "../../../../../config//index"
import { verifyBodyResponse } from "../../../../helpers/verifyBodyResponse"

const updateCategory = async (id, formData) => {
  try {
    const response = await axiosCreate.put(`inventory/subcategory/${id}`, formData, {
      timeout: 20000
    })

    return verifyBodyResponse(response, true)
  } catch (error) {
    return verifyBodyResponse(error, false)
  }
}

export default updateCategory